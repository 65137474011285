import { useStones } from "../../../hooks/useStones";
import { Paper } from "../../common/paper";
import { Brands } from "./brands";
import { MaterialSelect } from "../controls/type-selector-2";
import { Stones } from "./stones";
import { Pagination } from "./pagination";
import { ColorPicker } from "./colorpicker";
import { Search } from "./search";
import { H } from "../../common/H";

export const Materials = () => {
  const { stones, pagination, loading, setCurrentPage } = useStones();

  return (
    <Paper>
      <H>
        <MaterialSelect />
        <ColorPicker />
        <Search />
      </H>

      <Brands setCurrentPage={setCurrentPage} />
      <Stones stones={stones} />
      <Pagination
        currentPage={pagination.page}
        pageCount={pagination.pageCount}
        setCurrentPage={setCurrentPage}
        loading={loading}
      />
    </Paper>
  );
};
