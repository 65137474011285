import { useEffect, useState } from "react";

export const useScrollWidth = (isOpen: boolean = true) => {
  const [scrollWidth, setScrollWidth] = useState(0);

  useEffect(() => {
    const getScrollWidth = () => {
      const container = document.getElementById("scrollWidth");
      const scrollWidth = container
        ? container.offsetWidth - container.clientWidth
        : 0;
      return scrollWidth;
    };
    setScrollWidth(getScrollWidth());
  }, [isOpen]);

  return scrollWidth;
};
