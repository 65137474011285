import { FC, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { TMain } from '../../../types';
import { sinks } from '../controls/select-washing/dictionary';
import { api } from '../../../api/api';
import { Icon } from '../../common/icon';
import { AdditionalComments } from './additional-comments';
import s from './style.module.css';
import { createCountertopReport } from './reports/countetrops';

export const OrderList: FC<{ isHandlersPage?: boolean }> = ({
  isHandlersPage = false,
}) => {
  const { values, setFieldValue } = useFormikContext<TMain>();
  const {
    calculator,
    prices,
    settings: { isReadOnly, isLoggedIn },
  } = values;
  const { countertops, options } = calculator;
  // const {  sinkQuantity, isActive } = countertop;

  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [credentials, setCredentials] = useState<{
    login: string;
    password: string;
  } | null>(null);

  useEffect(() => {
    if (isLoggedIn) {
      setCredentials({
        login: localStorage.getItem('login') || '',
        password: localStorage.getItem('password') || '',
      });
    } else {
      setCredentials(null);
    }
  }, [isLoggedIn]);

  const bar = () => {
    const bar = values.calculator?.bar;

    if (!bar) return null;

    const {
      sizes: { width, length },
      edgeType,
    } = bar;

    return (
      <>
        <h4>Барная стойка</h4>
        <ul>
          <li>
            {length}см х {width}см
          </li>

          <li>Кромка: {edgeType}</li>
        </ul>
      </>
    );
  };

  const island = () => {
    const island = values.calculator?.island;

    if (!island) return null;

    const {
      sizes: { length, width, height, thickness },
      edgeType,
    } = island;

    return (
      <>
        <h4>Остров</h4>
        <ul>
          <li>{`${length}см х ${width}см х ${thickness}см `}</li>
          <li>{`Высота острова: ${height}см`}</li>
          <li>Кромка: {edgeType}</li>
          {island.sink ? (
            <li>
              {sinks.find((sn) => sn.title === island.sink)?.title}{' '}
              {sinks.length} шт.
            </li>
          ) : (
            <li>Без мойки</li>
          )}

          {island.hob ? (
            <li>Вырез под варочную панель</li>
          ) : (
            <li>Без варочной панели</li>
          )}
        </ul>
      </>
    );
  };

  const checkSinkAndEdge = () => {
    const currentSink = values.calculator.countertop.sink;
    const currentEdge = values.calculator.countertop.edgeType;
    const isQuarz = values.calculator.material.type === 'quartz';
    if (
      [
        'Двойная мойка',
        'Овальная мойка',
        'Круглая мойка',
        'Полукруглая мойка',
        'Прямоугольная мойка',
      ].find((m) => m === currentSink) &&
      isQuarz
    ) {
      setFieldValue('calculator.countertop.sink', 'Квадратная мойка');
    }

    if (
      [
        'Прямая 5',
        'Фигурная 1',
        'Фигурная 2',
        'Фигурная 3',
        'Фигурная 4',
        'Фигурная 5',
      ].find((m) => m === currentEdge) &&
      !isQuarz
    ) {
      setFieldValue('calculator.countertop.edgeType', 'Прямая 1');
    }
  };

  const handleButtonClick = async () => {
    setLoading(true);

    try {
      setFieldValue('calc_report', []);

      const { data: priceData } = await api.calcPrice(
        values.calculator,
        credentials,
        values.app.version,
        values.app.environment
      );

      if (priceData.errorMessage) {
        setFieldValue('error', priceData.errorMessage);
        return;
      }

      setFieldValue('prices', priceData.data);
      setFieldValue('calc_uid', priceData.calc_uid);

      if (credentials && priceData.data.length > 0 && isLoggedIn) {
        const { data: reportData } = await api.generateReport(
          priceData.calc_uid,
          credentials
        );

        if (reportData.errorMessage) {
          setFieldValue('error', reportData.errorMessage);
        } else if (reportData) {
          const arr = [];

          for (const key in reportData) {
            arr.push({ id: key, ...reportData[key] });
          }

          setFieldValue('calc_report', arr);
        }
      }
    } catch (error) {
      // Handle any unexpected errors here
      console.error('An error occurred:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div
        className={`${s.orderBlock} ${
          isHandlersPage ? s.orderBlockHandlers : ''
        }`}
        data-testid="order-block"
      >
        <div className={s.orderList} data-testid="order-list">
          {countertops.map((countertop) =>
            createCountertopReport(calculator, countertop)
          )}

          {(options.outlets ||
            options.ventHoles ||
            options.drainGroves ||
            options.hotRods) && (
            <>
              <h4>Опции:</h4>
              <ul>
                {options.outlets && (
                  <li>Дополнительные вырезы: {options.outlets}</li>
                )}
                {options.ventHoles && (
                  <li>Вентиляционные решетки для батареи</li>
                )}
                {options.drainGroves && <li>Проточки для стока воды</li>}
                {options.hotRods && <li>Подставки под горячее</li>}
              </ul>
            </>
          )}

          {bar()}
          {island()}
        </div>

        <AdditionalComments
          readOnly={isReadOnly || isHandlersPage}
          showForm={showForm}
          setShowForm={setShowForm}
        />
        {!isReadOnly && !isHandlersPage && (
          <button onClick={() => setShowForm(true)} className={s.addButton}>
            <Icon img="plus-blue" width={30} />
          </button>
        )}

        {!prices && !isReadOnly && (
          <button
            className={s.buttonCalc}
            disabled={loading}
            onMouseOver={checkSinkAndEdge}
            onClick={handleButtonClick}
            data-testid="calc-button"
          >
            {loading ? (
              <div className={s.ldsRing}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            ) : (
              <h4>Рассчитать</h4>
            )}
          </button>
        )}

        {prices && !isReadOnly && (
          <div className={s.buttonCalc}>
            <button
              className={s.buttonEdit}
              onClick={() => {
                setFieldValue('prices', null);
                setFieldValue('offer', []);
              }}
              data-testid="edit-button"
            >
              <Icon className={s.iconEdit} img="edit" />
              <h4>Редактировать</h4>
            </button>
          </div>
        )}
      </div>

      {/* <div>
        <pre>{JSON.stringify(values, null, 2)}</pre>
      </div> */}
    </>
  );
};
