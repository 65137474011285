import { useFormikContext } from 'formik';
import { FC } from 'react';
import { ProductOptionsMenu } from '../product-options-menu';
import { Paper } from '../../common/paper';
import { ButtonDelete } from '../controls/button-delete';
import { Subtitle } from '../controls/subtitle';
import { IslandImage } from './island-image';
import { TMain } from '../../../types';
import { IslandButton } from './island-button';
import { SizeControl } from '../controls/size-control';
import { Layer } from '../../common/layer';
import { SelectWashing } from '../controls/select-washing';
import { SelectCooking } from '../controls/select-hob-panel';
import { PreviewReadonly } from '../countertop/preview';
import s from './style.module.css';
import { SelectSize } from '../controls/select-size';

export const Island: FC<{ showMenu?: boolean }> = ({ showMenu = true }) => {
  const { island } = useFormikContext<TMain>().values.calculator;
  const { isMobile, isReadOnly } = useFormikContext<TMain>().values.settings;
  if (!island) return null;

  return (
    <Paper>
      <div className={s.header}>
        <Subtitle label="Остров" />
        {showMenu && <ButtonDelete product="island" />}
      </div>

      <div className={s.island} data-testid="island">
        <IslandImage />
        <IslandButton position="edge1" />
        <IslandButton position="edge2" />
        <IslandButton position="edge3" />
        <IslandButton position="edge4" />

        <Layer>
          <SizeControl
            fieldName="calculator.island.sizes.length"
            position={{ top: 14, left: 50 }}
            maxLength={4}
            range={{ min: 60, max: 1000 }}
          />
          <SizeControl
            fieldName="calculator.island.sizes.width"
            position={{ top: 42, left: 11 }}
            range={{ min: 60, max: 300 }}
          />
          {!isMobile && (
            <SelectSize
              label="Толщина"
              fieldName="calculator.island.sizes.thickness"
              position="island"
            />
          )}
          {!isMobile && (
            <>
              <SizeControl
                fieldName="calculator.island.sizes.height"
                position={{ top: 67, left: 82 }}
                range={{ min: 30, max: 200 }}
                label={`Высота\u00A0острова`}
              />

              <SelectWashing product="island" />
              <SelectCooking product="island" />
            </>
          )}
        </Layer>
      </div>

      {showMenu && <ProductOptionsMenu product="island" />}
      {isReadOnly && <PreviewReadonly product="island" />}
    </Paper>
  );
};
