import { FC } from "react";
import { useFormikContext } from "formik";
import type { TColor, TMain } from "../../../../types";
import all from "../../../../assets/colorpicker/all-colors.svg";
import allActive from "../../../../assets/colorpicker/all-colors-active.svg";
import check from "../../../../assets/colorpicker/check.svg";
import checkDark from "../../../../assets/colorpicker/check-dark.svg";
import s from "./style.module.css";

export const ColorPicker: FC = () => {
  const { values, setFieldValue } = useFormikContext<TMain>();

  const currentColor = values.filter.color;

  const handleClick = (color: TMain["filter"]["color"]) => {
    setFieldValue("filter.color", color);
  };

  const colors: TColor[] = [
    "white",
    "gray",
    "black",
    "beige",
    "orange",
    "brown",
  ];

  return (
    <div className={s.colors} data-testid="color-picker">
      <div className={s.allColors} onClick={() => handleClick(null)}>
        <img src={!currentColor ? allActive : all} alt="all" />
      </div>

      {colors.map((col) => {
        const active = currentColor === col;
        const isWhite = currentColor === "white";
        const style = [s[col]];
        if (active) style.push(s.active);
        return (
          <div
            onClick={() => handleClick(col)}
            className={style.join(" ")}
            key={col}
            data-testid="color"
          >
            {active && (
              <img
                className={s.check}
                src={isWhite ? checkDark : check}
                alt="v"
              />
            )}
          </div>
        );
      })}
    </div>
  );
};
