import { useFormikContext } from "formik";
import { FC } from "react";
import { TMain } from "../../../../types";
import s from "./style.module.css";

interface IIslandButton {
  position: "edge1" | "edge2" | "edge3" | "edge4";
}

export const IslandButton: FC<IIslandButton> = ({ position }) => {
  const { values, setFieldValue } = useFormikContext<TMain>();
  const { island } = values.calculator;

  if (!island) return null;

  const { edges } = island;
  const isSide = edges[position] === "side";
  const style = [s.iswitch, s[position], isSide && s.active].join(" ");

  const handleClick = () => {
    const fieldName = `calculator.island.edges.${position}`;
    if (!isSide) {
      setFieldValue(fieldName, "side");
    } else {
      setFieldValue(fieldName, "edge");
    }
  };

  return (
    <div className={style} onClick={handleClick} data-testid="island-button">
      <div className={s.tooltip}>
        <span className={s.tooltiptext}>Кромка</span>К
      </div>
      <div className={s.tooltip}>
        <span className={s.tooltiptext}>Опора</span>О
      </div>
    </div>
  );
};
