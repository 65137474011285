import { useFormikContext } from 'formik';
import { FC } from 'react';
import { TMain } from '../../../../types';
import { Icon } from '../../../common/icon';
import s from './style.module.css';

export const ShowMain: FC<{ pathname: string }> = ({ pathname }) => {
  const { values, setFieldValue } = useFormikContext<TMain>();
  const { isReadOnly } = values.settings;

  if (isReadOnly) return null;

  return (
    <div
      onClick={() => setFieldValue(`${pathname}.isActive`, true)}
      className={s.showMain}
      data-testid="show-main"
    >
      <Icon img="plus-blue" width={35} />
      <div>Показать столешницу</div>
    </div>
  );
};
