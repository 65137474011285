const allowedValues = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "del"];

const getInputChar = (currentValue: string, previousValue: string) => {
  let index = 0;
  if (currentValue.length < previousValue.length) {
    for (let c of previousValue) {
      if (c !== currentValue[index])
        return [currentValue[index] || "del", index];
      index += 1;
    }
  } else {
    for (let c of currentValue) {
      if (c !== previousValue[index]) return [c, index];
      index += 1;
    }
  }
  return ["", 0];
};

export const onChange = (
  currentValue: string,
  previousValue: string,
  setValue: React.Dispatch<React.SetStateAction<string>>,
  maxLength: number
) => {
  const [inputChar, index] = getInputChar(currentValue, previousValue);

  const hasMoreThree = currentValue.split(",")[0].length > maxLength;
  if (hasMoreThree && inputChar !== ".") return;

  if (inputChar === "0" && index === 0) return;
  if ((inputChar === "," || inputChar === ".") && index === 0) return;

  if (allowedValues.find((c) => c === inputChar)) setValue(currentValue);
  const hasComma = previousValue.includes(",");

  if (!hasComma && inputChar === ",") setValue(currentValue);
  if (!hasComma && inputChar === ".") setValue(currentValue.replace(".", ","));

  const hasMoreOne = hasComma && currentValue.split(",")[1]?.length > 1;
  if (hasMoreOne) setValue(previousValue);
};

export const inputWidth = (value: string, isFocus: boolean) => {
  const isFloat = value.indexOf(",") !== -1;
  const valueLength = isFloat ? value.length - 1 : value.length;
  const rightMargin = isFloat ? 12 : 8;
  const additionalWidth = isFocus ? 24 : 0;
  const unitCount = (value.match(/1/g) || []).length;
  const otherCount = valueLength - unitCount;
  return otherCount * 13 + unitCount * 9 + rightMargin + additionalWidth;
};

export const getErrorMessage = (
  value: string,
  range: { min: number; max: number } | undefined
) => {
  const valueNumber = Number(value.replace(",", "."));
  if (!range) return "";
  if (valueNumber > range.max) {
    return `Максимальный размер\u00A0${range.max}\u00A0см`;
  }
  if (valueNumber < range.min) {
    return `Минимальный размер\u00A0${range.min}\u00A0см`;
  }
  return "";
};
