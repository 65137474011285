export const isEmpty = (value: string): boolean => {
  if (!value?.length) return false;
  return true;
};

export const isEmail = (value: string): boolean => {
  const mailformat =
    // eslint-disable-next-line no-useless-escape
    /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  if (value.match(mailformat)) return true;
  return false;
};

export const isPhoneNumber = (value: string): boolean => {
  // eslint-disable-next-line no-useless-escape
  const onlyNumbers = value.replace(/[^0-9]/g, '');
  if (onlyNumbers.length === 11) return true;

  return false;
};

export const isMargin = (value: string): boolean => {
  if (value === '') {
    return true
  }
  else {
    let valueInt = parseInt(value)
    return Number.isInteger(valueInt) && valueInt <= 100 && valueInt >= -100
  }
};
