import { FC, useState } from "react";
import { useFormikContext } from "formik";
import { TMain } from "../../../../types";
import {TBrand, useBrands} from "../../../../hooks/useBrands";
import { Icon } from "../../../common/icon";
import s from "./style.module.css";

export const Brands: FC<{ setCurrentPage: (page: number) => void }> = ({
  setCurrentPage,
}) => {
  const brands: TBrand[] = useBrands();
  const { values, setFieldValue } = useFormikContext<TMain>();
  const { isMobile } = useFormikContext<TMain>().values.settings;
  const [showBrands, setShowBrands] = useState(false);
  const { brand } = values.calculator.material;
  const userBrands: string[] = values.user.brands
  let filterBrands: TBrand[] = brands
  if (userBrands.length > 0) {
    filterBrands = filterBrands.filter(x => userBrands.includes(x['attributes']['name']));
  }

  return (
    <div className={s.container} data-testid="brands">
      <div className={s.brandsList}>
        {filterBrands
          .slice(0, showBrands || !isMobile ? undefined : 6)
          .map(({ attributes }) => {
            const name = attributes.visible_name;
            const brandName = attributes.name;
            const thumb =
              attributes.photo.data.attributes.formats.thumbnail.url;
            const selectedClass = brandName === brand ? s.selected : "";

            return (
              <div
                onClick={() => {
                  if (brandName === brand) {
                    setFieldValue("calculator.material.brand", null);
                    setFieldValue("filter.brands", []);
                  } else {
                    setCurrentPage(1);
                    setFieldValue("calculator.material.brand", brandName);
                    setFieldValue("filter.brands", [brandName]);
                  }
                }}
                className={s.brandBlock}
                key={`brand_${brandName}`}
                data-testid="brand"
              >
                <div className={`${s.imgBlock} ${selectedClass}`}>
                  {selectedClass && (
                    <Icon className={s.ok} img="ok-blue" width={24} />
                  )}
                  <img className={s.brandImg} src={thumb} alt={name} />
                </div>
                <div className="brandName">{name}</div>
              </div>
            );
          })}
      </div>
      {brands.length > 6 && (
        <button
          className={`${s.moreBtn}`}
          onClick={() => {
            setShowBrands(!showBrands);
          }}
        >
          {showBrands ? "Скрыть бренды" : "Показать все бренды"}
        </button>
      )}
    </div>
  );
};
