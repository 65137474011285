import { FC } from 'react';
import s from './style.module.css';
import minus from '../../../../assets/common/step-minus.svg';
import plus from '../../../../assets/common/step-plus.svg';
import { useFormikContext } from 'formik';
import type { TCountertop, TMain } from '../../../../types';

export const InputStep: FC<{
  name: 'outlets' | 'sinkQuantity';
  countertop?: TCountertop;
  pathname?: string;
}> = ({ name, pathname, countertop }) => {
  const { values, setFieldValue } = useFormikContext<TMain>();
  const { outlets } = values.calculator.options;
  const sinkQuantity = countertop?.sinkQuantity;

  console.log(pathname);

  const sinkPath = pathname
    ? `${pathname}.sinkQuantity`
    : 'calculator.countertop.sinkQuantity';

  console.log(sinkPath);

  if (!outlets && name === 'outlets') return null;

  const decrease = () => {
    if (name === 'outlets' && outlets) {
      outlets > 1 && setFieldValue('calculator.options.outlets', outlets - 1);
    }

    if (name === 'sinkQuantity' && sinkQuantity) {
      sinkQuantity > 1 && setFieldValue(sinkPath, sinkQuantity - 1);
    }
  };

  const increase = () => {
    if (name === 'outlets' && outlets) {
      outlets < 10 && setFieldValue('calculator.options.outlets', outlets + 1);
    }

    if (name === 'sinkQuantity' && sinkQuantity) {
      sinkQuantity < 10 && setFieldValue(sinkPath, sinkQuantity + 1);
    }
  };

  const showValue = (() => {
    if (name === 'outlets') return outlets;
    if (name === 'sinkQuantity') return sinkQuantity;
  })();

  return (
    <div onClick={(e) => e.stopPropagation()} className={s.inputStep} data-testid="input-step">
      {name === 'outlets' && (
        <label className={`stepLabel ${s.label}`}>Количество</label>
      )}
      <div className={s.mainBlock}>
        <button onClick={decrease} className={s.minus}>
          <img src={minus} alt="-" />
        </button>
        <h4 className={s.number}>{showValue}</h4>
        <button onClick={increase} className={s.plus}>
          <img src={plus} alt="+" />
        </button>
      </div>
    </div>
  );
};
