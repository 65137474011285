import { useFormikContext } from 'formik';
import { FC, useEffect } from 'react';
import { TMain } from '../../../types';
import { MainGrid } from '../../common/grid';
import { Title } from '../controls/title';
import { OrderList } from '../order-list';
import { PricesOptionRouter } from './PricesOption';
import s from './style.module.css';

export const PricesList: FC = () => {
  const {
    prices,
    settings: { isMobile, isLoggedIn },
  } = useFormikContext<TMain>().values;

  useEffect(() => {
    const body: HTMLBodyElement | null = document.querySelector('body');
    body!.style.overflow = 'hidden';
    return () => {
      body!.style.overflow = 'auto';
    };
  }, []);

  if (!prices) return null;

  return (
    <div className={s.prices}>
      <div className={s.container}>
        <MainGrid
          title={<Title label="Обработчики" />}
          main={
            <>
              {!isLoggedIn && (
                <div className={s.banner}>
                  Сохрани расчёт и отправь заявку сейчас - получи бесплатный
                  замер.
                  <br /> Оплата только после выезда замерщика!
                </div>
              )}
              {prices.map((p) => (
                <PricesOptionRouter key={p.worker.name} p={p} />
              ))}
              {isMobile && <OrderList isHandlersPage />}
            </>
          }
          right={<OrderList isHandlersPage />}
        />
      </div>
    </div>
  );
};
