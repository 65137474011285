import { FC } from "react";
import { ProductItem } from "./product-item";

import style from "./style.module.css";

export const ProductMenu: FC = () => {
  return (
    <div className={style.container} data-testid="product-menu">
      <ProductItem
        label="Остров"
        text="многофункциональный стол в центре кухни и отличный элемент интерьера"
        product="island"
      />

      <ProductItem
        label="Барная стойка"
        text="столешница на опорах для продолжения рабочей области"
        product="bar"
      />
    </div>
  );
};
