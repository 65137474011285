import { FC } from "react";
import { useFormikContext } from "formik";
import islandImg from "../../../../assets/island/island.png";
import islandPanelFrontImg from "../../../../assets/island/island-panel-front.png";
import islandPanelBackImg from "../../../../assets/island/island-panel-back.png";
import islandPanelLeftImg from "../../../../assets/island/island-panel-left.png";
import islandPanelRightImg from "../../../../assets/island/island-panel-right.png";
import { TMain } from "../../../../types";
import { Layer } from "../../../common/layer";
import { E } from "../edge";

export const IslandImage: FC = () => {
  const { island } = useFormikContext<TMain>().values.calculator;
  if (!island) return null;

  const { edge1, edge2, edge3, edge4 } = island.edges;

  return (
    <>
      <img src={islandImg} alt="" style={{ maxWidth: "100%" }} />

      <Layer>
        {edge1 === "side" && <img src={islandPanelBackImg} alt="" />}
        {edge2 === "side" && <img src={islandPanelLeftImg} alt="" />}
        {edge3 === "side" && <img src={islandPanelFrontImg} alt="" />}
        {edge4 === "side" && <img src={islandPanelRightImg} alt="" />}
      </Layer>

      <Layer>
        <E position="edge1" />
        <E position="edge2" />
        <E position="edge3" />
        <E position="edge4" />
      </Layer>
    </>
  );
};
