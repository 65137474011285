import { FC, useEffect } from "react";
import ReactDOM from "react-dom";
import { useFormikContext } from "formik";
import { TMain } from "../../../types";
import cross from "../../../assets/common/cross.svg";
import s from "./styles.module.css";

interface IModal {
  title: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Modal: FC<IModal> = ({ children, title, setIsOpen }) => {
  const { isMobile } = useFormikContext<TMain>().values.settings;

  useEffect(() => {
    if (isMobile) {
      document.body.style.overflow = "hidden";
      document.body.style.marginRight = 17 + "px";
      return () => {
        document.body.style.overflow = "visible";
        document.body.style.marginRight = 0 + "px";
      };
    }
  }, [isMobile]);

  const node = document.getElementById("root") as HTMLElement;

  if (isMobile)
    return ReactDOM.createPortal(
      <div className={s.container}>
        <div className={s.wrap}>
          <div className={s.contentWrap}>
            <div className={s.head}>
              <h3 className={`${s.title}`}>{title}</h3>
              <div className={s.cross} onClick={() => setIsOpen(false)}>
                <img src={cross} alt=""></img>
              </div>
            </div>
            <div className={s.buffer}></div>
            {children}
          </div>
        </div>
      </div>,
      node
    );
  return <div>{children}</div>;
};
