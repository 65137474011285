import { useFormikContext } from 'formik';
import { FC, useEffect, useState } from 'react';
import { api } from '../../api/api';
import { TMain } from '../../types';
import style from './style.module.css';
import { DEMO_LOGIN, DEMO_PASSWORD } from '../../constants';

type TCredentials = {
  login: string;
  password: string;
};

type TWorker = {
  name: string;
  photo: string;
} | null;

type TBrands = string[];

export const LoginBar: FC = () => {
  const { setFieldValue } = useFormikContext<TMain>();

  const initialLogin = localStorage.getItem('login') || '';
  const initialPassword = localStorage.getItem('password') || '';

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [credentials, setCredentials] = useState<TCredentials>({
    login: initialLogin,
    password: initialPassword,
  });

  const [worker, setWorker] = useState<TWorker>(null);
  const [brands, setBrands] = useState<TBrands>([]);

  useEffect(() => {
    setFieldValue('settings.isLoggedIn', loggedIn);
    setFieldValue('user.brands', brands);
  }, [loggedIn, brands, setFieldValue]);

  const logMeIn = ({ login, password }: TCredentials) => {
    if (login && password) {
      setLoading(true);
      api
        .login(login, password)
        .then(({ name, photo, success, brands }) => {
          if (success) {
            setLoggedIn(true);
            localStorage.setItem('login', login);
            localStorage.setItem('password', password);
            setWorker({ name, photo });
            setError(false);
            setBrands(brands);
          } else {
            localStorage.removeItem('login');
            localStorage.removeItem('password');
            setWorker(null);
            setError(true);
          }
        })
        .finally(() => setLoading(false));
    }
  };

  const logMeOut = () => {
    setWorker(null);
    setLoggedIn(false);
    localStorage.removeItem('login');
    localStorage.removeItem('password');
  };

  useEffect(() => {
    if (initialLogin && initialPassword) {
      setLoading(true);
      api
        .login(initialLogin, initialPassword)
        .then(({ name, photo, success, brands }) => {
          if (success) setLoggedIn(true);
          setWorker({ name, photo });
          setBrands(brands);
        })
        .finally(() => setLoading(false));
    }
  }, [initialLogin, initialPassword]);

  return (
    <div className={style.loginBar}>
      <div className={style.workerInfo}>
        {worker && (
          <>
            <img
              style={{ width: '50px', margin: '-14px' }}
              alt={worker.name}
              src={worker.photo}
            />

            <h2>{worker.name}</h2>
          </>
        )}
      </div>

      {error && (
        <h3 style={{ color: 'white' }}>
          некорректные данные, повторите попытку
        </h3>
      )}

      {!loggedIn && (
        <div className={style.loginForm}>
          <input
            name="login"
            type="text"
            placeholder="логин"
            disabled={loading}
            onChange={(e) =>
              setCredentials({ ...credentials, login: e.target.value })
            }
            value={credentials.login}
          />
          <input
            name="pass"
            type="password"
            placeholder="пароль"
            disabled={loading}
            onChange={(e) =>
              setCredentials({ ...credentials, password: e.target.value })
            }
            value={credentials.password}
          />
          <button
            type="button"
            onClick={() => logMeIn({ ...credentials })}
            disabled={loading}
          >
            Войти
          </button>
        </div>
      )}

      {loggedIn && <button onClick={logMeOut}>Выйти</button>}
    </div>
  );
};

export const DemoBar: FC = () => {
  const { setFieldValue } = useFormikContext<TMain>();

  const initialLogin = DEMO_LOGIN;
  const initialPassword = DEMO_PASSWORD;

  const [loading, setLoading] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);

  const [worker, setWorker] = useState<TWorker>(null);

  useEffect(() => {
    setFieldValue('settings.isLoggedIn', loggedIn);
  }, [loggedIn, setFieldValue]);

  useEffect(() => {
    if (initialLogin && initialPassword) {
      localStorage.setItem('login', initialLogin);
      localStorage.setItem('password', initialPassword);
      setLoading(true);
      api
        .login(initialLogin, initialPassword)
        .then(({ name, photo, success }) => {
          if (success) setLoggedIn(true);
          setWorker({ name, photo });
        })
        .finally(() => setLoading(false));
    }
  }, [initialLogin, initialPassword]);

  return (
    <div className={style.loginBar}>
      <div className={style.workerInfo}>
        {worker && (
          <>
            <img
              style={{ width: '50px', margin: '-14px' }}
              alt={worker.name}
              src={worker.photo}
            />

            <h2>{worker.name} - демо калькулятора</h2>
          </>
        )}
      </div>
    </div>
  );
};
