import { TextareaAutosize } from '@mui/material';
import React, { FC, useState } from 'react';
import { TComment } from '../../../../../types';
import close from '../../../../../assets/close-comment/close-comment.svg';
import style from './style.module.css';

export const NewCommentForm: FC<{
  createComment: (comment: TComment) => void;
  changeComment: (comment: TComment) => void;
  maxIdPlus: () => number;
  setShowForm: React.Dispatch<React.SetStateAction<boolean>>;
  comment?: TComment;
}> = ({ createComment, changeComment, maxIdPlus, setShowForm, comment: c }) => {
  const initialComment = { id: 0, text: '', price: '' };

  const [comment, setComment] = useState<TComment>(c || initialComment);
  const [focused, setFocused] = useState<'textarea' | 'input' | null>(null);

  const create = () => {
    if (comment.text) {
      createComment({
        ...comment,
        id: maxIdPlus(),
        price: comment.price || '0',
      });
      setComment(initialComment);
      setShowForm(false);
    }
  };

  const save = () => {
    if (comment.text) {
      const { id, text, price } = comment;
      changeComment({ id, text, price: price || '0' });
    }
  };

  const cancel = () => {
    if (c) {
      changeComment({ ...c, edit: false });
    } else {
      setShowForm(false);
    }
  };

  return (
    <div className={style.newCommentForm}>
      <h2 className={style.header}>Новый параметр</h2>

      <label className={style.label} htmlFor="text">
        Название
      </label>

      <div className={style.wrapper}>
        {focused === 'textarea' && (
          <button
            onClick={() => setComment({ ...comment, text: '' })}
            className={style.cleanButton}
          >
            <img src={close} alt="x" />
          </button>
        )}
        <TextareaAutosize
          id="text"
          className={style.textArea}
          minRows={2}
          value={comment.text}
          onFocus={() => setFocused('textarea')}
          onBlur={() =>
            setTimeout(() => {
              setFocused(null);
            }, 200)
          }
          onChange={(e) => setComment({ ...comment, text: e.target.value })}
          placeholder="Название параметра"
        />
      </div>

      <label className={style.label} htmlFor="price">
        Цена, ₽
      </label>

      <div className={style.wrapper}>
        {focused === 'input' && (
          <button
            onClick={() => setComment({ ...comment, price: '' })}
            className={style.cleanButton}
          >
            <img src={close} alt="x" />
          </button>
        )}
        <input
          id="price"
          type="number"
          placeholder="Цена"
          onFocus={() => setFocused('input')}
          onBlur={() =>
            setTimeout(() => {
              setFocused(null);
            }, 200)
          }
          className={style.inputPrice}
          value={comment?.price || ''}
          onChange={(e) => setComment({ ...comment, price: e.target.value })}
        />
      </div>

      {c && (
        <button className={style.buttonAdd} onClick={save}>
          Сохранить
        </button>
      )}

      {!c && (
        <button className={style.buttonAdd} onClick={create}>
          Добавить
        </button>
      )}

      <button className={style.buttonCancel} onClick={cancel}>
        Отмена
      </button>
    </div>
  );
};
