import { useFormikContext } from "formik";
import { FC } from "react";
import { TMain } from "../../../../types";
import s from "./style.module.css";

interface IE {
  position: "edge1" | "edge2" | "edge3" | "edge4";
}

// Остров: грань
export const E: FC<IE> = ({ position }) => {
  const { island } = useFormikContext<TMain>().values.calculator;
  if (!island) return null;
  const edges = island.edges;

  const style = [s.e, s[position], edges[position] === "side" && s.g].join(" ");
  return <div className={style} />;
};
