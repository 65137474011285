import { FC } from 'react';
import { TCountertop } from '../../../../../types';
import './style.css';

interface ILine {
  type: 'edge' | 'plinth' | null;
  position:
    | 'innerLeft'
    | 'outerLeft'
    | 'innerMiddle'
    | 'outerMiddle'
    | 'innerRight'
    | 'outerRight'
    | 'outerBottom'
    | 'outerLeftBottom'
    | 'outerRightBottom';
  countertop: TCountertop;
}

export const Line: FC<ILine> = ({ type, position, countertop }) => {
  const { type: countertopType } = countertop;

  const cn = type ? type : 'dotted';

  return <div className={`line c-${countertopType}-${position} ${cn}`}></div>;
};
