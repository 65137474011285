import React, { FC, useEffect, useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import { useKeyPress } from '../../../../hooks/useKeyPress';
import { TMain } from '../../../../types';
import { getErrorMessage, onChange } from './utils';

import style from './styles.module.css';

export const SizeControlMobile: FC<{
  fieldName: string; // Для Formik
  position?: { top: number; left: number }; // позиционирование в %, по умолчанию отсутствует
  s?: {
    // Стиль внешнего вида, по умолчанию черный инпут
    [key: string]: string;
  };
  enableMobile?: boolean;
  maxLength?: number; // Максимальное количество целых цифр ввода, по умолчанию 3
  label?: string; // лейбл
  range?: { min: number; max: number }; // валидационный диапазон
}> = ({ fieldName, s = style, maxLength = 3, range }) => {
  const { setFieldValue, setFieldError, getFieldMeta } =
    useFormikContext<TMain>();

  const { error } = getFieldMeta(fieldName);
  const formikValue = String(getFieldMeta(fieldName).value || '').replace(
    '.',
    ','
  );

  const [value, setValue] = useState<string>(formikValue);
  const [hasError, setHasError] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useKeyPress((event: KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === 'Escape') {
      inputRef?.current?.blur();
    }
  });

  useEffect(() => {
    setValue(String(formikValue).replace('.', ','));
  }, [formikValue]);

  return (
    <input
      type="text"
      inputMode="numeric"
      value={value || ''}
      onChange={(e) => onChange(e.target.value, value, setValue, maxLength)}
      onBlur={() => {
        if (value.slice(-1) === ',') setValue(value.slice(0, -1));
        if (value.slice(-2) === ',0') setValue(value.slice(0, -2));
        if (!value) setValue(formikValue);

        setFieldValue(
          fieldName,
          Number(value.replace(',', '.') || formikValue)
        );
        const errorMessage = getErrorMessage(value, range);
        setFieldError(fieldName, errorMessage);
        setHasError(!!errorMessage);
        setTimeout(() => {
          setHasError(false);
        }, 3000);
      }}
      onFocus={() => {
        setHasError(false);
        inputRef.current?.select();
      }}
      onContextMenu={(e) => e.preventDefault()}
      ref={inputRef}
      className={`${hasError ? s.inputDigitalError : s.inputDigital} ${
        s.input
      }`}
    />
  );
};
