import { FC } from 'react';
import { TCountertop } from '../../../../types';
import { Layer } from '../../../common/layer';
import { Line } from './line';

export const Edges: FC<{ countertop: TCountertop }> = ({ countertop }) => {
  const { type, edges, isActive } = countertop;

  const { edge1, edge2, edge3, edge4, edge5, edge6, edge7, edge8 } = edges;

  if (!isActive) return null;

  if (type === 'U')
    return (
      <Layer>
        <Line type={edge1} position="outerMiddle" countertop={countertop} />
        <Line type={edge2} position="outerLeft" countertop={countertop} />
        <Line type={edge3} position="outerLeftBottom" countertop={countertop} />
        <Line type={edge4} position="innerLeft" countertop={countertop} />
        <Line type={edge5} position="innerMiddle" countertop={countertop} />
        <Line type={edge6} position="innerRight" countertop={countertop} />
        <Line
          type={edge7}
          position="outerRightBottom"
          countertop={countertop}
        />
        <Line type={edge8} position="outerRight" countertop={countertop} />
      </Layer>
    );

  if (type === 'L')
    return (
      <Layer>
        <Line type={edge1} position="outerMiddle" countertop={countertop} />
        <Line type={edge2} position="outerRight" countertop={countertop} />
        <Line type={edge3} position="outerLeftBottom" countertop={countertop} />
        <Line type={edge4} position="outerLeft" countertop={countertop} />
        <Line type={edge5} position="innerMiddle" countertop={countertop} />
        <Line type={edge6} position="innerLeft" countertop={countertop} />
      </Layer>
    );

  if (type === 'R')
    return (
      <Layer>
        <Line type={edge1} position="outerMiddle" countertop={countertop} />
        <Line type={edge2} position="outerLeft" countertop={countertop} />
        <Line
          type={edge3}
          position="outerRightBottom"
          countertop={countertop}
        />
        <Line type={edge4} position="outerRight" countertop={countertop} />
        <Line type={edge5} position="innerMiddle" countertop={countertop} />
        <Line type={edge6} position="innerRight" countertop={countertop} />
      </Layer>
    );

  if (type === 'Q') {
    return (
      <Layer>
        <Line type={edge1} position="outerMiddle" countertop={countertop} />
        <Line type={edge2} position="outerLeft" countertop={countertop} />
        <Line type={edge3} position="outerBottom" countertop={countertop} />
        <Line type={edge4} position="outerRight" countertop={countertop} />
      </Layer>
    );
  }

  return null;
};
