import { useEffect } from "react";

export const useKeyPress = (listener: (ev: KeyboardEvent) => void) =>
  useEffect(() => {
    window.addEventListener("keydown", listener);
    return () => {
      window.removeEventListener("keydown", listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
