import React, { FC, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { TComment, TMain } from '../../../../types';
import TextareaAutosize, {
  TextareaAutosizeProps as TAprops,
} from 'react-textarea-autosize';

import { NewCommentForm } from './new-comment';
import close from '../../../../assets/close-comment/close-comment.svg';
import style from './style.module.css';

const TA: FC<TAprops> = (props: TAprops) => <TextareaAutosize {...props} />;

interface IAdditionalComments {
  showForm: boolean;
  readOnly: boolean;
  setShowForm: React.Dispatch<React.SetStateAction<boolean>>;
}

// Additional comments
export const AdditionalComments: FC<IAdditionalComments> = ({
  showForm,
  readOnly,
  setShowForm,
}) => {
  const { values, setFieldValue } = useFormikContext<TMain>();

  const [orderComment, setOrderComment] = useState<string | null>(
    values.calculator.orderComment || ''
  );
  const [comments, setComments] = useState<TComment[]>(
    values.calculator.comments
  );

  const maxIdPlus = () => comments.sort((a, b) => b.id - a.id)[0]?.id + 1 || 1;

  const changeComment = (comment: TComment) => {
    const newComments = comments.map((comm) =>
      comment.id === comm.id ? comment : comm
    );
    setComments(newComments);
  };

  const deleteComment = (id: number) => {
    const newComments = comments.filter((c) => c.id !== id);
    setComments(newComments);
  };

  const editComment = (id: number) => {
    const newComments = comments.map((c) =>
      c.id === id ? { ...c, edit: true } : c
    );
    setComments(newComments);
  };

  const createComment = (comment: TComment) => {
    if (comment.text.split(' ').join('') || comment.price)
      setComments([...comments, comment]);
  };

  useEffect(() => {
    // console.table(comments);
    setFieldValue('calculator.comments', comments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comments]);

  useEffect(() => {
    setFieldValue('calculator.orderComment', orderComment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderComment]);

  const CommentBox: FC<{ comment: TComment }> = ({ comment }) => {
    const { id, price, text } = comment;
    return (
      <div
        onClick={() => !readOnly && editComment(id)}
        className={style.commentBox}
      >
        <div className={style.commentBoxText}>{text}</div>
        {price && <div className={style.commentBoxPrice}>{price} ₽</div>}
        {!readOnly && (
          <div
            onClick={(e) => {
              e.stopPropagation();
              deleteComment(id);
            }}
            className={style.delete}
          >
            <img src={close} alt="x" />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={style.comments}>
      {comments
        .sort((a, b) => a.id - b.id)
        .map((comment) => {
          if (comment.edit) {
            return (
              <NewCommentForm
                key={comment.id}
                comment={comment}
                createComment={createComment}
                changeComment={changeComment}
                maxIdPlus={maxIdPlus}
                setShowForm={setShowForm}
              />
            );
          }

          return <CommentBox key={comment.id} comment={comment} />;
        })}

      {showForm && (
        <NewCommentForm
          createComment={createComment}
          changeComment={changeComment}
          maxIdPlus={maxIdPlus}
          setShowForm={setShowForm}
        />
      )}
      {
        <TA
          minRows={2}
          key={crypto.randomUUID()}
          // onChange={(e: any) => setOrderComment(e.target.value)}
          onBlur={(e: any) => setOrderComment(e.target.value || null)}
          disabled={readOnly}
          className={`${style.textarea} ${style.taBlack}`}
          placeholder="Комментарий к заказу"
          defaultValue={orderComment || ''}
        />
      }
    </div>
  );
};
