import { FC } from 'react';
import { TCountertop } from '../../../../types';
import { ButtonSwitch } from '../../controls/button-switch';

export const Switches: FC<{ countertop: TCountertop; pathname: string }> = ({
  countertop,
  pathname,
}) => {
  const { type, isActive } = countertop;

  if (!isActive) return null;

  return (
    <>
      {type === 'Q' && (
        <>
          <ButtonSwitch position="edge1" pathname={pathname} />
          <ButtonSwitch position="edge2" pathname={pathname} />
          <ButtonSwitch position="edge3" pathname={pathname} />
          <ButtonSwitch position="edge4" pathname={pathname} />
        </>
      )}

      {(type === 'L' || type === 'R') && (
        <>
          <ButtonSwitch position="edge1" pathname={pathname} />
          <ButtonSwitch position="edge2" pathname={pathname} />
          <ButtonSwitch position="edge3" pathname={pathname} />
          <ButtonSwitch position="edge4" pathname={pathname} />
          <ButtonSwitch position="edge5" pathname={pathname} />
          <ButtonSwitch position="edge6" pathname={pathname} />
        </>
      )}

      {type === 'U' && (
        <>
          <ButtonSwitch position="edge1" pathname={pathname} />
          <ButtonSwitch position="edge2" pathname={pathname} />
          <ButtonSwitch position="edge3" pathname={pathname} />
          <ButtonSwitch position="edge4" pathname={pathname} />
          <ButtonSwitch position="edge5" pathname={pathname} />
          <ButtonSwitch position="edge6" pathname={pathname} />
          <ButtonSwitch position="edge7" pathname={pathname} />
          <ButtonSwitch position="edge8" pathname={pathname} />
        </>
      )}
    </>
  );
};
