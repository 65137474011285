import { FormikContextType } from 'formik';
import { useEffect, useState } from 'react';
import { TMain } from '../types';

export const useMobile = (formik: FormikContextType<TMain>) => {
  const { setFieldValue } = formik;
  const [isMobile, setIsMobile] = useState(
    document.documentElement.clientWidth <= 1000
  );
  const [isMobileStones, setIsMobileStones] = useState(
    document.documentElement.clientWidth <= 480
  );

  useEffect(() => {
    const resizeListener = () => {
      setIsMobile(document.documentElement.clientWidth <= 1000);
      setIsMobileStones(document.documentElement.clientWidth <= 480);
    };
    window.addEventListener('resize', resizeListener);
    return () => window.removeEventListener('resize', resizeListener);
  }, []);

  useEffect(() => {
    setFieldValue('settings.isMobile', isMobile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);
  useEffect(() => {
    setFieldValue('settings.isMobileStones', isMobileStones);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobileStones]);
};
