import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { api } from "../api/api";
import { TMain } from "../types";

export type TBrand = {
  id: number;
  attributes: {
    createdAt: string;
    material: string;
    name: string;
    photo: {
      data: {
        attributes: {
          formats: {
            thumbnail: {
              url: string;
            };
          };
        };
      };
    };
    updatedAt: string;
    visible: true;
    visible_name: string;
  };
};

export const useBrands = () => {
  const [brands, setBrands] = useState<TBrand[]>([]);
  const { values, setFieldValue } = useFormikContext<TMain>()
  const { type } = values.calculator.material



  useEffect(() => {
    api.getBrands(type).then(({ data }) => {
      const brandList = data.map((b: TBrand) => b.attributes.name);
      setFieldValue("filter.brands", brandList);
      setBrands(data)
    });
  }, [type, setFieldValue]);
  return brands;
};
