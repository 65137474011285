import { FC } from 'react';
import { IReview } from '../prices-option';
import { IPrice } from '../../../../../types';
import { getMonthWord } from '../../../../../utils/getMonthWord';
import { Icon } from '../../../../common/icon';
import s from './styles.module.css';

export const ReviewsModal: FC<{
  price: IPrice['price'];
  worker: IPrice['worker'];
  reviews?: IReview[];
}> = ({ worker, price, reviews }) => {
  const Rewiew: FC<{ review: IReview }> = ({ review }) => {
    const { author, content, date } = review.attributes;
    const reviewDate = new Date(date);
    return (
      <div className={s.review}>
        <div className={`stepLabel ${s.textGrey}`}>
          {`${author} · ${reviewDate.getDate()} ${getMonthWord(reviewDate)}`}
        </div>

        <div className={s.reviewContent}>{content}</div>
      </div>
    );
  };

  if (!reviews) return null;
  return (
    <div className={s.container}>
      <div className={s.header}>
        <h5>Отзывы</h5>
        <div className={s.ratingStar}>
          <Icon img="star" />
          <h4>{worker.rating} </h4>
        </div>
      </div>

      <h5 className={s.workerName}>{worker.name}</h5>

      {reviews.map((review) => (
        <Rewiew review={review} />
      ))}
      <button className={s.priceButton}>
        <h3>12 дней</h3>
        <h3>{`${price}\u00A0₽`}</h3>
      </button>
    </div>
  );
};
