import { FC } from "react";
import s from "./style.module.css";

export const Spinner: FC = () => {
  return (
    <div className={s["lds-spinner"]}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
