import { INoPriceReasonTypeMap } from './types';

export const API_BASE_URL: string = `${process.env.REACT_APP_API_BASE_URL || 'https://api.topklik.online'}`
export const CMS_BASE_URL: string = `${process.env.REACT_APP_CMS_BASE_URL || 'https://cms.topklik.online'}`
export const PUBLIC_CALC_ROUTES: string[] = []
export const DEMO_LOGIN: string = 'test@topklik.online'
export const DEMO_PASSWORD: string = ''
export const CONTACT_PHONE: string = '+7 903 130-54-77'
export const CONTACT_EMAIL: string = 'info@topklik.online'
export const SUPPORT_LINK: string = 'https://t.me/Topklik_help'
export let NO_PRICE_REASON_TYPE_MAP: INoPriceReasonTypeMap = {
    NoStonePrice: 'На данный камень не установлена стоимость. Артикул может быть:\n- новинкой;\n- снят с производства;\n- не включен в программу камнеобработчика.\nДля уточнения обратитесь к камнеобработчику',
    NoPlinthPrice: 'Стоимость плинтуса не установлена. Для уточнения обратитесь к камнеобработчику',
    NoPanelPrice: 'Стоимость панели не установлена. Для уточнения обратитесь к камнеобработчику',
    NoPanelsCalibrationPrice: 'Стоимость на калибровку панели не установлена. Для уточнения обратитесь к камнеобработчику',
    NoPartsJointPrice: 'Стоимость стыков не установлена. Для уточнения обратитесь к камнеобработчику',
    NoEdgePrice: 'Стоимость кромки не установлена. Для уточнения обратитесь к камнеобработчику',
    NoSinkPrice: 'Стоимость мойки не установлена. Для уточнения обратитесь к камнеобработчику',
    NoHobPrice: 'Стоимость варочной панели не установлена. Для уточнения обратитесь к камнеобработчику',
    NoOutletsPrice: 'Стоимость вырезов не установлена. Для уточнения обратитесь к камнеобработчику',
    NoVentHolesPrice: 'Стоимость вентиляционных отверстий не установлена. Для уточнения обратитесь к камнеобработчику',
    NoDrainGrovesPrice: 'Стоимость проточек для стока воды не установлена. Для уточнения обратитесь к камнеобработчику',
    NoHotRodsPrice: 'Стоимость подставок под горячее не установлена. Для уточнения обратитесь к камнеобработчику',
    NoWorkPrice: 'Стоимость работ не установлена. Для уточнения обратитесь к камнеобработчику',
    NoMaterialPrice: 'Стоимость материала не установлена. Для уточнения обратитесь к камнеобработчику',
}
