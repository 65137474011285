import { useFormikContext } from "formik";
import { FC } from "react";
import { TMain } from "../../../../types";
import s from "./style.module.css";

export const Search: FC = () => {
  const { values, setFieldValue } = useFormikContext<TMain>();

  return (
    <input
      placeholder="Поиск"
      className={s.searchBar}
      value={values.filter.searchString}
      onChange={(e) => setFieldValue("filter.searchString", e.target.value)}
      data-testid="search"
    />
  );
};
