import React, { FC } from "react";
import close from "../../../../assets/close/close.svg";
import s from "./style.module.css";

export const Modal: FC<{ content?: React.ReactNode; onClose?: () => void }> = ({
  content,
  onClose,
}) => {
  if (!content) return null;

  return (
    <div className={s.modalOverlay}>
      <div className={s.modal}>
        <img className={s.closeIcon} alt="х" src={close} onClick={onClose} />

        <div className={s.modalContent}>{content}</div>
        <div className={s.modalClose} onClick={onClose} data-testid="close-button">
          Закрыть
        </div>
      </div>
    </div>
  );
};
