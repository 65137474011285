import React, { FC } from 'react';
import { useFormikContext } from 'formik';
import { TMain } from '../../../../types';
import toggleActive from '../../../../assets/top-toggle/active.svg';
import toggleInactive from '../../../../assets/top-toggle/inactive.svg';
import s from './style.module.css';
import { get } from 'lodash';

export const ShowHideTop: FC<{ pathname: string }> = ({ pathname }) => {
  const { values, setFieldValue } = useFormikContext<TMain>();
  const { isActive } = get(values, pathname);
  const { isMobile } = values.settings;

  const handleToggle = () => {
    setFieldValue(`${pathname}.isActive`, !isActive);
  };

  const toggleClass = isActive ? s.toggle : s.toggleMobile;
  const toggleImage = isActive ? toggleActive : toggleInactive;
  const toggleText = isMobile ? 'Скрыть' : 'Скрыть столешницу';

  return (
    <div className={toggleClass} onClick={handleToggle} data-testid="hide-countertop">
      {isMobile && <div>{toggleText}</div>}
      <img
        className={isMobile ? s.toggleMobileImg : s.toggleImg}
        src={toggleImage}
        alt="toggle"
      />
      {!isMobile && <div>{toggleText}</div>}
    </div>
  );
};
