import { FC, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { IOption, TCountertop, TMain } from '../../../../types';
import { TopButton } from '../../product-options-menu/top-button';
import { useScrollWidth } from '../../../../hooks/useScrollWidth';
import { edges } from './dictionary';
import line from '../../../../assets/common/line.svg';
import s from './styles.module.css';

export const SelectFrontEdgeCountertop: FC<{
  countertop: TCountertop;
  index: number;
}> = ({ countertop, index }) => {
  const { setFieldValue } = useFormikContext<TMain>();

  const [selectedEdge, setSelectedEdge] = useState(edges[0]);

  useEffect(() => {
    const currentEdge = edges.find((edg) => edg.title === countertop.edgeType);
    setSelectedEdge(currentEdge || edges[0]);
  }, [countertop.edgeType]);

  const [isOpen, setIsOpen] = useState(false);
  const scrollWidth = useScrollWidth(isOpen);

  // Set

  const productEdge = `calculator.countertops[${index}].edgeType`;
  const setEdgeType = (val: string) => setFieldValue(productEdge, val);

  const Field: FC<{ edge: IOption; index: number }> = ({ edge, index }) => {
    const { img, title, description } = edge;
    return (
      <div
        className={s.option}
        onClick={() => {
          setIsOpen(false);
          setSelectedEdge(edge);
          setEdgeType(title);
        }}
      >
        <img className={s.img} src={img} alt="" />
        <div
          className={`selectTitle ${
            edge.title === selectedEdge.title ? s.selectedTitle : ''
          }`}
        >
          {title}
          <br />
          <span className={s.description}>{description}</span>
        </div>
        {edges.length - 1 !== index && (
          <img className={s.line} src={line} alt="" />
        )}
      </div>
    );
  };

  return (
    <div className={s.selectBlock}>
      <TopButton
        label={selectedEdge.title}
        active={!!productEdge}
        onClick={() => setIsOpen(!isOpen)}
        type="frontEdge"
        img={selectedEdge.img}
        isOpen={isOpen}
      />
      {isOpen && (
        <div
          className={s.scrollContainer}
          style={{ width: `${296 - scrollWidth}px` }}
        >
          <div id="scrollWidth" className={s.options}>
            {edges.map((edge, index) => (
              <Field key={edge.title} edge={edge} index={index} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
