import { useFormikContext } from "formik";
import { FC } from "react";
import { TMain } from "../../../types";
import s from "./style.module.css";

export const ErrorMessage: FC<{ message: string }> = ({ message }) => {
  const { setFieldValue } = useFormikContext<TMain>();
  return (
    <div className={s.errorMessage} data-testid="error-message">
      <h1 className={s.errorMessageTitle}>Ошибка</h1>
      <pre>{message}</pre>
      <button
        onClick={() => {
          setFieldValue("error", null);
        }}
      >
        Закрыть
      </button>
    </div>
  );
};
