import s1 from "../../../../assets/edges/s1.png";
import s2 from "../../../../assets/edges/s2.png";
import s3 from "../../../../assets/edges/s3.png";
import s4 from "../../../../assets/edges/s4.png";
import s5 from "../../../../assets/edges/s5.png";
import f1 from "../../../../assets/edges/f1.png";
import f2 from "../../../../assets/edges/f2.png";
import f3 from "../../../../assets/edges/f3.png";
import f4 from "../../../../assets/edges/f4.png";
import f5 from "../../../../assets/edges/f5.png";
import { IOption } from "../../../../types";

export const edges: IOption[] = [
  {
    title: "Прямая 1",
    description: "",
    img: s1,
  },
  {
    title: "Прямая 2",
    description: "",
    img: s2,
  },
  {
    title: "Прямая 3",
    description: "",
    img: s3,
  },
  {
    title: "Прямая 4",
    description: "",
    img: s4,
  },
  {
    title: "Прямая 5",
    description: "Только кварц",
    img: s5,
  },
  {
    title: "Фигурная 1",
    description: "Только кварц",
    img: f1,
  },
  {
    title: "Фигурная 2",
    description: "Только кварц",
    img: f2,
  },
  {
    title: "Фигурная 3",
    description: "Только кварц",
    img: f3,
  },
  {
    title: "Фигурная 4",
    description: "Только кварц",
    img: f4,
  },
  {
    title: "Фигурная 5",
    description: "Только кварц",
    img: f5,
  },
];
