import { FC, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { api } from '../../../api/api';
import { TMain } from '../../../types';
import { Countertop } from '../countertop';
import { Island } from '../island';
import { Bar } from '../bar';
import { OrderList } from '../order-list';
import { IPrice } from '../../../types';
import { PricesOptionRouter } from '../prices/PricesOption';
import { StoneExample } from './stone-example';
import { Spinner } from '../../common/spinner';
import s from './style.module.css';
import { ErrorMessage } from '../errorMessage';

export const KP: FC = () => {
  const { values, setValues, setFieldValue } = useFormikContext<TMain>();

  let error = values.error;

  const [loading, setLoading] = useState(true);

  const [contact, setContact] = useState({
    email: '',
    name: '',
    phone: '',
  });

  const [order, setOrder] = useState({
    id: 0,
    date: '',
  });

  const [agent, setAgent] = useState({
    show: false,
    email: '',
    photo: '',
    name: '',
    phone: '',
  });

  const [stone, setStone] = useState({
    brand: '',
    material: '',
    name: '',
    photo: '',
  });

  const [price, setPrice] = useState<IPrice>({
    price: null,
    currency: 'RUB',
    no_price_reason: null,
    worker: {
      id: 0,
      name: '',
      photo: '',
      rating: 0,
      review: {
        author: '',
        content: '',
        date: '',
        rating: 0,
      },
      production_days: 0,
      description1: null,
      description2: null,
    },
  });

  useEffect(() => {
    // http://localhost:3000/offer?uid=d84ec05140a7463aa6e59292d8ff0b52
    const uid = new URLSearchParams(document.location.search).get('uid');

    api
      .getOrder(uid!)
      .then((data) => {
        if (data.errorMessage) {
          setFieldValue('error', data.errorMessage);
        } else {
          setValues({
            ...values,
            calculator: {
              ...values.calculator,
              ...data.parameters,
            },
          });

          setOrder({
            id: data.id,
            date: data.date,
          });

          let worker;

          if (data.worker) {
            worker = { ...price.worker, ...data.worker };
          } else {
            worker = { ...price.worker, ...data.agent };
          }

          setPrice({
            ...price,
            worker: worker,
            price: data.price,
          });

          if (data.agent) {
            setAgent({
              show: true,
              name: data.agent.name,
              photo: data.agent.photo,
              email: data.agent.email,
              phone: data.agent.phone || '',
            });
          }

          setContact({
            name: data.contact.name || '',
            email: data.contact.email || '',
            phone: data.contact.phone,
          });

          setStone(data.stone);
        }

        setFieldValue('settings.isReadOnly', true);
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={s.kp}>
      {loading && (
        <div className={s.loader}>
          <Spinner />
        </div>
      )}

      {!loading && (
        <>
          <div className={s.left}>
            <div className={s.screen}></div>
            <h1
              className={s.offer_number}
              data-testid="order-number"
            >{`КП № ${order.id} от ${order.date}`}</h1>
            <h1 data-testid="client">{`Клиент: ${contact.name}, ${contact.phone}, ${contact.email}`}</h1>
            {agent.show && (
              <h1 data-testid="agent">{`Салон: ${agent.name}, ${agent.phone}, ${agent.email}`}</h1>
            )}
            <PricesOptionRouter p={price} />
            {values.calculator.countertops.map((countertop, i) => (
              <Countertop
                countertop={countertop}
                pathname={`calculator.countertops[${i}]`}
                index={i}
              />
            ))}

            <Island showMenu={false} />
            <Bar />
            <div className={s.additionalConditions} data-testid="additional-conditions">
              <h1>Дополнительные условия</h1>

              <ol>
                <li>
                  Окончательная стоимость заказа формируется после выезда
                  замерщика и при наличии выбранного материала на складе
                </li>
                <li>
                  Дополнительные работы, не включенные в параметры расчета,
                  обсуждаются с производителем
                </li>
                <li>Замер, доставка и установка включены в стоимость заказа</li>
                <li>
                  Стоимость изделия указана в рублях РФ и действует до конца
                  рабочего дня, в связи с конвертацией стоимости материала по
                  курсу ЦБ РФ на день просчета
                </li>
              </ol>
            </div>
          </div>

          <div className={s.right}>
            <div className={s.additionalConditionsMobile}>
              <h1>Дополнительные условия</h1>

              <ol>
                <li>
                  Окончательная стоимость заказа формируется после выезда
                  замерщика и при наличии выбранного материала на складе
                </li>
                <li>
                  Дополнительные работы, не включенные в параметры расчета,
                  обсуждаются с производителем
                </li>
                <li>Замер, доставка и установка включены в стоимость заказа</li>
                <li>
                  Стоимость изделия указана в рублях РФ и действует до конца
                  рабочего дня, в связи с конвертацией стоимости материала по
                  курсу ЦБ РФ на день просчета
                </li>
              </ol>
            </div>
            <OrderList />
            <StoneExample {...stone} />
          </div>
        </>
      )}
      {error && (
        <ErrorMessage message="На сервере произошла непредвиденная ошибка" />
      )}
    </div>
  );
};
