import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { IPrice } from '../../../../types';
import { PricesOption } from './prices-option';
import { PricesOptionPrivate } from './prices-option-private';
import { PUBLIC_CALC_ROUTES } from '../../../../constants';

export const PricesOptionRouter: FC<{ p: IPrice }> = ({ p }) => {
  const { pathname } = useLocation();
  if (PUBLIC_CALC_ROUTES.includes(pathname)) {
    return <PricesOption p={p} />;
  } else {
    return <PricesOptionPrivate p={p} />;
  }
};
