import { FC } from 'react';
import { TCountertop } from '../../../../types';
import { PanelShowHide } from '../../controls/pannel-show-hide';
import { Pannel } from './panel';

export const Panels: FC<{ countertop: TCountertop; pathname: string }> = ({
  countertop,
  pathname,
}) => {
  const { panels } = countertop;
  if (!panels.length) return null;

  return (
    <>
      <PanelShowHide countertop={countertop} pathname={pathname} />
      <Pannel countertop={countertop} />
    </>
  );
};
