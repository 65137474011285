import w1 from "../../../../assets/sinks/w1.png";
import w2 from "../../../../assets/sinks/w2.png";
import w3 from "../../../../assets/sinks/w3.png";
import w4 from "../../../../assets/sinks/w4.png";
import w5 from "../../../../assets/sinks/w5.png";
import w6 from "../../../../assets/sinks/w6.png";
import { IOption } from "../../../../types";

export const sinks: IOption[] = [
  {
    title: "Без мойки",
    description: "",
    img: "",
  },
  {
    title: `Вырез под Вашу мойку крепление снизу`,
    description: "",
    img: "",
  },
  {
    title: `Вырез под Вашу мойку крепление сверху`,
    description: "",
    img: "",
  },
  {
    title: "Квадратная мойка",
    description: "В цвет камня",
    img: w3,
  },
  {
    title: "Двойная мойка",
    description: "В цвет камня (только акрил)",
    img: w1,
  },
  {
    title: "Овальная мойка",
    description: "В цвет камня (только акрил)",
    img: w2,
  },

  {
    title: "Круглая мойка",
    description: "В цвет камня (только акрил)",
    img: w4,
  },
  {
    title: "Полукруглая мойка",
    description: "В цвет камня (только акрил)",
    img: w5,
  },
  {
    title: "Прямоугольная мойка",
    description: "В цвет камня (только акрил)",
    img: w6,
  },
];
