import { FC } from 'react';
import { Icon } from '../../../common/icon';
import s from './style.module.css';

interface IButton {
  onClick: () => void;
  disabled?: boolean;
  type?:
    | 'plus-blue'
    | 'plus-red'
    | 'ok-blue'
    | 'left'
    | 'right'
    | 'plus-green'
    | 'ok-green';
  num?: number | '...';
  isActive?: boolean;
}

export const Button: FC<IButton> = ({
  onClick,
  type,
  num,
  isActive,
  disabled = false,
}) => {
  if (num) {
    return (
      <button
        className={`${s.button} ${isActive ? s.active : ''}`}
        onClick={onClick}
        disabled={disabled}
        data-testid={"button-" + num}
      >
        <span className={s.num}>{num}</span>
      </button>
    );
  }

  if (type) {
    return (
      <button
        className={`${s.button} ${s[type]}`}
        onClick={onClick}
        disabled={disabled}
        data-testid={"button-" + type}
      >
        <Icon img={type} width={36} />
      </button>
    );
  }

  return <span>{`:(`}</span>;
};
