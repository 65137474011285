import { FC } from 'react';
import { useFormikContext } from 'formik';
import { TopButton } from './top-button';
import { TopMobileMenu } from './top-menu';
import wallLedgeImg from '../../../assets/ledges/wallLedge.png';
import { TCountertop, TMain } from '../../../types';
import s from './style.module.css';
import { SelectFrontEdgeCountertop } from '../controls/select-front-edge-countertop';

export const ProductOptionsMenuCountertop: FC<{
  countertop: TCountertop;
  pathname: string;
  index: number;
}> = ({ countertop, pathname, index }) => {
  const { values } = useFormikContext<TMain>();
  const panels = countertop?.panels;

  const isPlinth = (): boolean =>
    !!countertop?.edges && Object.values(countertop.edges).includes('plinth');

  if (values.settings.isReadOnly) return null;

  return (
    <div className={s.countertopOtionsMenu} data-testid="product-options-menu-countertop">
      <TopMobileMenu product="countertop" />
      <SelectFrontEdgeCountertop countertop={countertop} index={index} />
      <TopButton
        countertop={countertop}
        pathname={pathname}
        label="Стеновая панель"
        active={panels.length > 0}
        type="panels"
      />
      <TopButton
        countertop={countertop}
        pathname={pathname}
        label="Плинтус"
        active={isPlinth()}
        type="wallLedge"
        img={wallLedgeImg}
      />
    </div>
  );
};
