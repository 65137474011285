import { FC, useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import { IOption, TCountertop, TMain, TProduct } from '../../../../types';
import { Icon } from '../../../common/icon';
import { Modal } from '../../../common/modal/Modal';
import { hobs } from './dictionary';
import hobImg from '../../../../assets/hob/hob.png';
import s from './styles.module.css';
import { useOutside } from '../../../../hooks/useOutside';

export const SelectCooking: FC<{
  product: TProduct;
  countertop?: TCountertop;
}> = ({ product }) => {
  const { setFieldValue, values } = useFormikContext<TMain>();
  const { isMobile } = useFormikContext<TMain>().values.settings;
  const { hob } = values.calculator[product]!;

  const [isOpen, setIsOpen] = useState(false);

  const position = values.calculator[product]?.hob ? 'selected' : 'empty';

  const selectedTitle = hobs.find((arg) => arg.title === hob);

  const setSelectedCooking = (field: string, value: any) => {
    if (value === 'Без варочной панели') {
      setFieldValue(field, null);
    } else {
      setFieldValue(field, value);
    }
  };

  const wrapperRef = useRef(null);
  useOutside(wrapperRef, () => setIsOpen(false));

  const Field: FC<{ washing: IOption; index: number }> = ({
    washing: hob,
    index,
  }) => {
    const { img, title } = hob;
    return (
      <div
        className={s.option}
        onClick={() => {
          setIsOpen(false); //! rerender if comment line
          // setCooking(hob);
          setSelectedCooking(`calculator.${product}.hob`, hob.title);
        }}
      >
        <div className={s.optionLine}>
          {img && <img className={s.img} src={img} alt="" />}
          <div
            className={`selectTitle ${
              hob.title === values.calculator[product]?.hob
                ? s.selectedTitle
                : ''
            }`}
          >
            {title}
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className={`${s.selectBlock} ${s[`${product}-${position}`]}`} data-testid="select-hob-panel">
      <button
        className={s.button}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {hob ? (
          <div>
            <div
              className={`${s.selectTitle} ${isOpen && s.selectedTitleActive}`}
            >
              <div className={s.titleContainer}>
                <div className={s.title}>
                  {selectedTitle?.title || 'Без варочной панели'}
                </div>
                <Icon
                  className={s.arrow}
                  img={isOpen ? 'arrow-up-blue' : 'arrow-down-black'}
                />
              </div>
            </div>
            <img className={s.washingImg} src={hobImg} alt="" />
          </div>
        ) : (
          <div className={s.titleContainer}>
            <div className={s.title}>Без варочной панели</div>
            <Icon
              img={isOpen ? 'arrow-up-blue' : 'arrow-down-black'}
              className={s.arrow}
            />
          </div>
        )}
      </button>

      {isOpen && (
        <div
          className={s.scrollContainer}
          style={hob && !isMobile ? { top: '50%' } : undefined}
          ref={!isMobile ? wrapperRef : null}
        >
          <div id="scrollWidth" className={s.options}>
            <Modal title="Мойка" setIsOpen={setIsOpen}>
              {hobs.map((washing, index) => (
                <Field key={washing.title} washing={washing} index={index} />
              ))}
            </Modal>
          </div>
        </div>
      )}
    </div>
  );
};
