import { FC, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { TMain, TPanelsPosition, TProduct } from '../../../../types';
import { SelectCooking } from '../../controls/select-hob-panel';
import { SelectSize } from '../../controls/select-size';
import { SelectWashing } from '../../controls/select-washing';
import { SizeControl } from '../../controls/size-control';
import s from './style.module.css';
import ms from './styleMobilePanelSize.module.css';
import { SizeControlMobile } from '../../controls/size-control-mobile';
import { SelectSizeMobile } from '../../controls/select-size-mobile';

export const TopMobileMenu: FC<{ product: TProduct }> = ({ product }) => {
  const { values } = useFormikContext<TMain>();

  interface IMContainer {
    title: string;
  }

  const MenuContainer: FC<IMContainer> = ({ children, title }) => {
    return (
      <div>
        <div className={`${s.title} stepLabel`}>{title}</div>
        <div className={s.mobileOptionMenu}>{children}</div>
      </div>
    );
  };

  const activePanelsList = values.calculator.countertop.panels.filter(
    ({ isActive }) => !!isActive
  );

  const panels = values.calculator.countertop.panels;

  const getIdByName = (name: TPanelsPosition) =>
    panels.map((p) => p.name).indexOf(name);

  useEffect(() => {
    console.table(panels);
  }, [panels]);

  return (
    <div className={`${s.mobileOptions} ${s[product]}`} data-testid="top-menu">
      {product === 'countertop' && (
        <MenuContainer title="Толщина столешницы">
          <SelectSize
            fieldName="calculator.countertop.sizes.thickness"
            position="thickness"
          />
        </MenuContainer>
      )}

      <MenuContainer title="Мойка">
        <SelectWashing product={product} />
      </MenuContainer>

      <div className={s.hob}>
        <MenuContainer title="Варочная панель">
          <SelectCooking product={product} />
        </MenuContainer>
      </div>

      {product === 'countertop' ? (
        <div className={s.panels}>
          <div
            style={{
              fontSize: '17px',
              fontWeight: '600',
              paddingBottom: '16px',
            }}
          >
            Стеновые панели
          </div>

          <div className={s.mobilePanelsMenu}>
            {activePanelsList.map(({ name }, i) => (
              <div key={name} className={s.panelSizeItem}>
                <span className={s.panelTitle}>{`${i + 1} панель, см`}</span>
                <div className={s.panelInputs}>
                  <div style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                    <span>В</span>
                    <SizeControlMobile
                      fieldName={`calculator.countertop.panels[${getIdByName(
                        name
                      )}].height`}
                      range={{ min: 60, max: 300 }}
                    />
                  </div>

                  <div>
                    <span>Д</span>
                    <SizeControlMobile
                      fieldName={`calculator.countertop.panels[${getIdByName(
                        name
                      )}].width`}
                      range={{ min: 60, max: 300 }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <>
          <div className={s.height}>
            <div
              className={`${s.title} stepLabel`}
            >{`Высота\u00A0острова`}</div>
            <div className={s.islandHeight}>
              <SizeControl
                fieldName="calculator.island.sizes.height"
                s={ms}
                enableMobile
                range={{ min: 90, max: 200 }}
              />
            </div>
          </div>

          <SelectSizeMobile
            label="Толщина"
            fieldName="calculator.island.sizes.thickness"
            position="island"
          />
        </>
      )}
    </div>
  );
};
