import { TCountertop, TMain } from '../../../../types';

const getCounterTopName = (type: TCountertop['type']) => {
  if (type === 'Q') return 'Прямая столешница';
  if (type === 'L') return 'Г-образная столешница';
  if (type === 'R') return 'Г-образная столешница';
  if (type === 'U') return 'П-образная столешница';
  return 'ошибка типа';
};

const parseUid = (uid: string | null) => {
  if (!uid) return '';
  const [material, brand, code] = uid.split(':');

  return (
    <>
      <li>{`Материал: ${
        material === 'acryl' ? 'Акрил' : 'Кварц'
      } ${brand} ${code}`}</li>
    </>
  );
};

export const createCountertopReport = (
  calculator: TMain['calculator'],
  countertop: TCountertop
) => (
  <>
    <h4>{getCounterTopName(countertop.type)}</h4>
    <ul className="pm14">
      <li>
        {Object.values(countertop.sizes).map((v, i) => {
          if (v > 0 && i !== Object.values(countertop.sizes).length - 1) {
            return v && `${v}см х `;
          }
          if (v !== 0) {
            return `${v}см`;
          }

          return '';
        })}
      </li>

      {parseUid(calculator.material.uid)}

      {countertop.sink ? (
        <li>
          {/* {countertop.sinks.find((sn) => sn.title === countertop.sink)?.title}{' '} */}
          {countertop.sinkQuantity} шт.
        </li>
      ) : (
        <li>Без мойки</li>
      )}

      {countertop.hob ? (
        <li>Вырез под варочную панель</li>
      ) : (
        <li>Без варочной панели</li>
      )}

      <li>Кромка: {countertop.edgeType}</li>

      {Object.values(countertop.edges).includes('plinth') && (
        <li>С бортиком</li>
      )}
    </ul>

    {countertop.panels.length > 0 &&
      !!countertop.panels.find(({ isActive }) => isActive) && (
        <>
          <h4>Панели</h4>
          <ul>
            {countertop.panels.map(
              ({ isActive, width, height, name }, i) =>
                isActive && (
                  <li key={name}>{`[${i + 1}] ${width}cм х ${height}cм`}</li>
                )
            )}
          </ul>
        </>
      )}
  </>
);
