import React, { FC, useEffect, useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import { useKeyPress } from '../../../../hooks/useKeyPress';
import { TMain } from '../../../../types';
import { getErrorMessage, inputWidth, onChange } from './utils';

import style from './styles.module.css';

export const SizeControl: FC<{
  fieldName: string; // Для Formik
  position?: { top: number; left: number }; // позиционирование в %, по умолчанию отсутствует
  s?: {
    // Стиль внешнего вида, по умолчанию черный инпут
    [key: string]: string;
  };
  enableMobile?: boolean;
  maxLength?: number; // Максимальное количество целых цифр ввода, по умолчанию 3
  label?: string; // лейбл
  range?: { min: number; max: number }; // валидационный диапазон
}> = ({
  fieldName,
  position,
  s = style,
  enableMobile,
  label,
  maxLength = 3,
  range,
}) => {
  const { setFieldValue, setFieldError, getFieldMeta } =
    useFormikContext<TMain>();

  const { error } = getFieldMeta(fieldName);
  const formikValue = String(getFieldMeta(fieldName).value || '').replace(
    '.',
    ','
  );

  const [value, setValue] = useState<string>(formikValue);
  const [hasError, setHasError] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const inputStyle = enableMobile
    ? undefined
    : {
        width: `${inputWidth(value, isFocus)}px`,
      };

  useKeyPress((event: KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === 'Escape') {
      inputRef?.current?.blur();
    }
  });

  useEffect(() => {
    setValue(String(formikValue).replace('.', ','));
  }, [formikValue]);

  if (!formikValue) return null;
  return (
    <div
      className={s.inputSize}
      style={position && { top: `${position.top}%`, left: `${position.left}%` }}
      data-testid="size-control"
    >
      {!!label && <label className="pm14">{label}</label>}
      <div className={s.container}>
        <div
          className={`${!!error ? s.inputBlockError : s.inputBlock} ${
            isFocus ? s.inputBlockFocus : ''
          }`}
          onClick={() => inputRef.current!.focus()}
        >
          <input
            type="text"
            inputMode="numeric"
            value={value || ''}
            onChange={(e) =>
              onChange(e.target.value, value, setValue, maxLength)
            }
            onBlur={() => {
              if (value.slice(-1) === ',') setValue(value.slice(0, -1));
              if (value.slice(-2) === ',0') setValue(value.slice(0, -2));
              if (!value) setValue(formikValue);
              setFieldValue(
                fieldName,
                Number(value.replace(',', '.') || formikValue)
              );
              setIsFocus(false);
              const errorMessage = getErrorMessage(value, range);
              setFieldError(fieldName, errorMessage);
              setHasError(!!errorMessage);
              setTimeout(() => {
                setHasError(false);
              }, 3000);
            }}
            style={inputStyle}
            onFocus={() => {
              setIsFocus(true);
              setHasError(false);
              inputRef.current?.select();
            }}
            ref={inputRef}
            className={`inputDigital ${s.input}`}
          />
        </div>
        {hasError && <div className={`pm14 ${s.error}`}>{error}</div>}
      </div>
    </div>
  );
};
