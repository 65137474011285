import { FC } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { KP } from '../calculator/kp';
import { Calculator } from '../calculator';
import { TMain } from '../../types';
import { initialValues } from './initialValues';
import { useMobile } from '../../hooks/useMobile';
import { FooterMini } from '../landing/footer-mini';
import './inter.css';
import { LoginBar, DemoBar } from '../login';

export const App: FC = () => {
  const formik = useFormik<TMain>({
    initialValues,
    validateOnChange: false,
    validationSchema: null,
    onSubmit: () => new Promise(() => null),
  });

  useMobile(formik);

  return (
    <BrowserRouter>
      <FormikProvider value={formik}>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <LoginBar />
                <Calculator />
                <FooterMini />
              </>
            }
          />

          <Route
            path="/demo"
            element={
              <>
                <DemoBar />
                <Calculator />
              </>
            }
          />
          <Route path="/offer" element={<KP />} />
        </Routes>
      </FormikProvider>
    </BrowserRouter>
  );
};
