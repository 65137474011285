import { FC } from 'react';
import { useFormikContext } from 'formik';
import { TopButton } from './top-button';
import { TopMobileMenu } from './top-menu';
import { SelectFrontEdge } from '../controls/select-front-edge';
import wallLedgeImg from '../../../assets/ledges/wallLedge.png';
import { TMain } from '../../../types';
import s from './style.module.css';

export const ProductOptionsMenu: FC<{
  product: 'bar' | 'island';
}> = ({ product }) => {
  const { values } = useFormikContext<TMain>();

  const isSide =
    values.calculator.island?.edges.edge1 === 'side' ||
    values.calculator.island?.edges.edge2 === 'side' ||
    values.calculator.island?.edges.edge3 === 'side' ||
    values.calculator.island?.edges.edge4 === 'side';

  if (values.settings.isReadOnly) return null;

  return (
    <div className={s.countertopOtionsMenu} data-testid="product-options-menu">
      {product !== 'bar' && <TopMobileMenu product={product} />}
      <SelectFrontEdge product={product} />

      {product === 'island' && (
        <TopButton
          label="Опора"
          active={isSide}
          type="lowering"
          img={wallLedgeImg}
        />
      )}
    </div>
  );
};
