import { FC, useState } from 'react';
import { useFormikContext } from 'formik';
import { TEdgeName, TMain } from '../../../../types';
import { Icon } from '../../../common/icon';
import { Close } from '../close';
import s from './style.module.css';
import { MobileSwitch } from './mobile-switch';
import { get } from 'lodash';

interface IButtonSwitch {
  position: TEdgeName;
  pathname: string;
}

export const ButtonSwitch: FC<IButtonSwitch> = ({ position, pathname }) => {
  const [visible, setVisible] = useState(false);
  const [visibleMobile, setVisibleMobile] = useState(false);
  const { values, setFieldValue } = useFormikContext<TMain>();
  const { isMobile, isReadOnly } = values.settings;
  const { type, edges } = get(values, pathname);
  const name = `${pathname}.edges.${position}`;
  const edgeType = edges[position] as 'edge' | 'plinth';
  const styleName = isMobile
    ? s[`${edgeType}SwitchMobile`]
    : s[`${edgeType}Switch`];

  const handleChange = (val?: 'plinth' | 'edge' | null) => {
    setFieldValue(
      name,
      val === null ? null : edgeType === 'edge' ? 'plinth' : 'edge'
    );
  };

  const handleMobile = () => setVisibleMobile(!visibleMobile);

  if (edges[position]) {
    return (
      <div
        onMouseOver={() => !isMobile && setVisible(true)}
        onMouseLeave={() =>
          !isMobile && setTimeout(() => setVisible(false), 1000)
        }
        onClick={isMobile ? handleMobile : undefined}
        className={`${styleName} ${s[type]} ${s[position]}`}
        style={isMobile && visibleMobile ? { zIndex: '2' } : undefined} data-testid="edge-or-plinth"
      >
        {!isMobile && (
          <>
            <button onClick={() => handleChange()} className={s.tooltip}>
              <span className={s.tooltiptext}>Кромка</span>К
            </button>
            <button onClick={() => handleChange()} className={s.tooltip}>
              <span className={s.tooltiptext}>Плинтус</span>П
            </button>
            {visible && <Close onClick={() => handleChange(null)} />}
          </>
        )}

        {isMobile && visibleMobile && (
          <MobileSwitch
            fieldName={name}
            onOutside={setVisibleMobile}
            edgeType={edgeType}
          />
        )}
      </div>
    );
  }

  return (
    <>
      {!isReadOnly && (
        <button
          className={`${s.addButton} ${s[type]} ${s[position]}`}
          onClick={() => setFieldValue(name, 'edge')}
          data-testid="add-edge-or-plinth"
        >
          <Icon img="plus-blue" width={30} />
        </button>
      )}
    </>
  );
};
