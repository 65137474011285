import { FC } from 'react';
import { useFormikContext } from 'formik';
import { TMain } from '../../../../types';
import { countertopL, countertopR } from '../../../app/defaultValues';
import rImg from '../../../../assets/icons/r-top.svg';
import rImgBlack from '../../../../assets/icons/r-top-black.svg';
import lImg from '../../../../assets/icons/l-top.svg';
import lImgBlack from '../../../../assets/icons/l-top-black.svg';
import s from './style.module.css';
import { get } from 'lodash';

type TActiveTab = 'L' | 'R';

export const TopLRSelector: FC<{ pathname: string }> = ({ pathname }) => {
  const { values, setFieldValue } = useFormikContext<TMain>();
  const countertopType: TActiveTab = get(values, pathname).type;

  if (!['L', 'R'].includes(countertopType)) return null;

  const handleClick = (type: TActiveTab) => {
    setFieldValue(pathname, type === 'L' ? countertopL : countertopR);
  };

  const getImage = (type: TActiveTab) => {
    if (type === 'L') {
      return countertopType === 'L' ? lImg : lImgBlack;
    }
    if (type === 'R') {
      return countertopType === 'R' ? rImg : rImgBlack;
    }
  };

  const tabs: TActiveTab[] = ['L', 'R'];

  return (
    <div className={s.typeSelector}>
      {tabs.map((type) => {
        console.log(countertopType === type);
        return (
          <button
            key={type}
            onClick={() => handleClick(type)}
            className={`${s.button} ${countertopType === type ? s.active : ''}`}
            data-testid={"countertop-lr-type-" + type.toLowerCase()}
          >
            <img src={getImage(type)} alt={type} />
          </button>
        );
      })}
    </div>
  );
};
