import { FC } from 'react';
import { useFormikContext } from 'formik';
import { TCountertop, TMain, TPanelsPosition } from '../../../../types';
import { Icon } from '../../../common/icon';
import s from './style.module.css';

export const PanelShowHide: FC<{
  countertop: TCountertop;
  pathname: string;
}> = ({ countertop, pathname }) => {
  const { setFieldValue, values } = useFormikContext<TMain>();
  const { type, panels } = countertop;
  const { isReadOnly } = values.settings;

  const handleClick = (name: TPanelsPosition) => {
    const newPanels = panels.map((p) =>
      p.name === name ? { ...p, isActive: !p.isActive } : p
    );
    setFieldValue(`${pathname}.panels`, newPanels);
  };

  if (isReadOnly) return null;

  const activePanelsList = panels.filter(({ isActive }) => isActive);
  const getIdByName = (name: TPanelsPosition) =>
    activePanelsList.map((p) => p.name).indexOf(name);

  return (
    <>
      {panels.map(({ isActive, name }, i) =>
        isActive ? (
          // Иконка закрыть (х)
          <button
            key={name}
            className={`${s.closeButton} ${s[name]} ${s[type]}`}
            onClick={() => handleClick(name)}
            data-testid="remove-panel"
          >
            <Icon className={s.closeIcon} img="close" width={10} />
            <div className={s.number}>{getIdByName(name) + 1}</div>
          </button>
        ) : (
          // Иконка добавить (+)
          <button
            key={name}
            className={`${s.addButton} ${s[name]}  ${s[type]}`}
            onClick={() => handleClick(name)}
            data-testid="add-panel"
          >
            <Icon img="plus-blue" width={30} />
          </button>
        )
      )}
    </>
  );
};
