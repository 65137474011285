import { FC, useEffect, useReducer } from "react";
import { useFormikContext } from "formik";
import { TMain } from "../../../../types";
import { Button } from "../../controls/button";
import s from "./style.module.css";

interface IPagination {
  currentPage: number;
  pageCount: number;
  loading: boolean;
  setCurrentPage: (page: number) => void;
}

interface IRange {
  begin: number;
  end: number;
}

const numbers = (begin: number, end: number) => {
  const nums = [];
  for (let i = begin; i <= end; i += 1) {
    nums.push(i);
  }
  return nums;
};

export const Pagination: FC<IPagination> = ({
  currentPage,
  pageCount,
  setCurrentPage,
  loading,
}) => {
  const { isMobile } = useFormikContext<TMain>().values.settings;
  const pageLimit = isMobile ? 4 : 9;

  const [range, setRange] = useReducer(
    (range: IRange, prop: IRange) => {
      const endPage = () => {
        if (prop.begin === pageCount - pageLimit - 1) return pageCount;
        if (prop.begin === pageCount - (pageLimit + 2)) return pageCount - 3;
        return prop.end;
      };
      return { begin: prop.begin, end: endPage() };
    },
    {
      begin: 1,
      end: pageLimit,
    }
  );

  const isFinalPage = !(range.end === pageCount);

  useEffect(() => {
    setRange({
      begin: 1,
      end: pageCount >= pageLimit ? pageLimit : pageCount,
    });
  }, [pageCount, pageLimit]);

  useEffect(() => {
    setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  if (!pageCount) return null;
  return (
    <div className={s.pagination} data-testid="pagination">
      <Button
        type="left"
        onClick={() => {
          if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
          }
          if (currentPage === range.begin && range.begin > 1) {
            setRange({ begin: range.begin - 1, end: range.end - 1 });
          }
        }}
        disabled={loading}
      />

      {numbers(range.begin, range.end).map((i) => (
        <Button
          key={i}
          num={i}
          onClick={() => setCurrentPage(i)}
          isActive={currentPage === i}
          disabled={loading}
        />
      ))}

      {isFinalPage && (
        <>
          <Button
            num="..."
            onClick={() => {
              const isEndPage = range.end + pageLimit >= pageCount;
              setCurrentPage(
                isEndPage ? pageCount - pageLimit - 1 : currentPage + pageLimit
              );
              setRange(
                isEndPage
                  ? {
                      begin: pageCount - pageLimit - 1,
                      end: pageCount,
                    }
                  : {
                      begin: range.begin + pageLimit,
                      end: range.end + pageLimit,
                    }
              );
            }}
            disabled={loading}
          />
          <Button
            num={pageCount}
            onClick={() => {
              setCurrentPage(pageCount);
              setRange({ begin: pageCount - pageLimit - 1, end: pageCount });
            }}
            disabled={loading}
            isActive={currentPage === pageCount}
          />
        </>
      )}

      <Button
        type="right"
        onClick={() => {
          if (currentPage < pageCount) {
            setCurrentPage(currentPage + 1);
          }
          if (currentPage === range.end && range.end < pageCount)
            setRange({ begin: range.begin + 1, end: range.end + 1 });
        }}
        disabled={loading}
      />
    </div>
  );
};
