import { TMain } from '../../types';

// Настройки по умолчанию
const LENGTH1 = 300;
const LENGTH2 = 60;
const LENGTH3 = 60;
const WIDTH1 = 60;
const WIDTH2 = 150;
const WIDTH3 = 150;
const HEIGHT_PANEL = 60;
const HEIGHT_ISLAND = 100;
const THICKNESS = 2;
const THICKNESS_ISLAND = 2;

// Барная стойка
export const barDefault: TMain['calculator']['bar'] = {
  sizes: {
    length: LENGTH1,
    width: WIDTH1,
  },
  edgeType: 'Прямая 1',
};

// Остров
export const islandDefault: TMain['calculator']['island'] = {
  sizes: {
    length: LENGTH1,
    width: WIDTH1,
    height: HEIGHT_ISLAND,
    thickness: THICKNESS_ISLAND,
  },
  edges: {
    edge1: 'edge',
    edge2: 'edge',
    edge3: 'side',
    edge4: 'edge',
  },
  edgeType: 'Прямая 1',
  sink: null,
  hob: false,
};

export const countertopQ: TMain['calculator']['countertop'] = {
  isActive: true,
  type: 'Q',
  sizes: {
    length1: LENGTH1,
    width1: WIDTH1,
    length2: 0,
    width2: 0,
    length3: 0,
    width3: 0,
    thickness: THICKNESS,
  },
  edges: {
    edge1: null,
    edge2: null,
    edge3: 'edge',
    edge4: 'plinth',
    edge5: null,
    edge6: null,
    edge7: null,
    edge8: null,
  },
  edgeType: 'Прямая 1',
  wallLedge: true,
  sink: null,
  sinkQuantity: 0,
  hob: null,
  panels: [
    {
      name: 'left',
      width: WIDTH1.toString(),
      height: HEIGHT_PANEL.toString(),
      isActive: false,
    },
    {
      name: 'front',
      width: LENGTH1.toString(),
      height: HEIGHT_PANEL.toString(),
      isActive: true,
    },
    {
      name: 'right',
      width: WIDTH1.toString(),
      height: HEIGHT_PANEL.toString(),
      isActive: false,
    },
  ],
};

// Столешница Г-образная левая
export const countertopL: TMain['calculator']['countertop'] = {
  isActive: true,
  type: 'L',
  sizes: {
    length1: LENGTH1,
    width1: WIDTH1,
    length2: LENGTH2,
    width2: WIDTH2,
    length3: 0,
    width3: 0,
    thickness: THICKNESS,
  },
  edges: {
    edge1: null,
    edge2: null,
    edge3: 'edge',
    edge4: 'plinth',
    edge5: 'edge',
    edge6: 'edge',
    edge7: null,
    edge8: null,
  },
  edgeType: 'Прямая 1',
  wallLedge: true,
  sink: null,
  sinkQuantity: 0,
  hob: null,
  panels: [
    {
      name: 'left',
      width: WIDTH2.toString(),
      height: HEIGHT_PANEL.toString(),
      isActive: false,
    },
    {
      name: 'front',
      width: LENGTH1.toString(),
      height: HEIGHT_PANEL.toString(),
      isActive: true,
    },
    {
      name: 'right',
      width: WIDTH1.toString(),
      height: HEIGHT_PANEL.toString(),
      isActive: false,
    },
    {
      name: 'leftBottom',
      width: LENGTH2.toString(),
      height: HEIGHT_PANEL.toString(),
      isActive: false,
    },
  ],
};

// Столешница Г-образная правая
export const countertopR: TMain['calculator']['countertop'] = {
  isActive: true,
  type: 'R',
  sizes: {
    length1: LENGTH1,
    width1: WIDTH1,
    length2: LENGTH2,
    width2: WIDTH2,
    length3: 0,
    width3: 0,
    thickness: THICKNESS,
  },
  edges: {
    edge1: null,
    edge2: null,
    edge3: 'edge',
    edge4: 'plinth',
    edge5: 'edge',
    edge6: 'edge',
    edge7: null,
    edge8: null,
  },
  edgeType: 'Прямая 1',
  wallLedge: true,
  sink: null,
  sinkQuantity: 0,
  hob: null,
  panels: [
    {
      name: 'left',
      width: WIDTH1.toString(),
      height: HEIGHT_PANEL.toString(),
      isActive: false,
    },
    {
      name: 'front',
      width: LENGTH1.toString(),
      height: HEIGHT_PANEL.toString(),
      isActive: true,
    },
    {
      name: 'right',
      width: WIDTH2.toString(),
      height: HEIGHT_PANEL.toString(),
      isActive: false,
    },
    {
      name: 'rightBottom',
      width: LENGTH2.toString(),
      height: HEIGHT_PANEL.toString(),
      isActive: false,
    },
  ],
};

// Столешница П-образная
export const countertopU: TMain['calculator']['countertop'] = {
  isActive: true,
  type: 'U',
  sizes: {
    length1: LENGTH1,
    width1: WIDTH1,
    length2: LENGTH2,
    width2: WIDTH2,
    length3: LENGTH3,
    width3: WIDTH3,
    thickness: THICKNESS,
  },
  edges: {
    edge1: null,
    edge2: 'plinth',
    edge3: 'edge',
    edge4: 'edge',
    edge5: 'edge',
    edge6: 'edge',
    edge7: 'edge',
    edge8: 'plinth',
  },
  edgeType: 'Прямая 1',
  wallLedge: true,
  sink: null,
  sinkQuantity: 0,
  hob: null,
  panels: [
    {
      name: 'front',
      width: LENGTH1.toString(),
      height: HEIGHT_PANEL.toString(),
      isActive: true,
    },
    {
      name: 'left',
      width: WIDTH2.toString(),
      height: HEIGHT_PANEL.toString(),
      isActive: false,
    },
    {
      name: 'right',
      width: WIDTH3.toString(),
      height: HEIGHT_PANEL.toString(),
      isActive: false,
    },
    {
      name: 'leftBottom',
      width: LENGTH2.toString(),
      height: HEIGHT_PANEL.toString(),
      isActive: false,
    },
    {
      name: 'rightBottom',
      width: LENGTH3.toString(),
      height: HEIGHT_PANEL.toString(),
      isActive: false,
    },
  ],
};

export const getCountertopDefault = (
  type: string
): TMain['calculator']['countertop'] => {
  if (type === 'Q') {
    return countertopQ;
  } else if (type === 'R') {
    return countertopR;
  } else if (type === 'L') {
    return countertopL;
  } else {
    return countertopU;
  }
};
