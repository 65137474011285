import { FC, useState, useRef } from 'react';
import { useFormikContext } from 'formik';
import { IOption, TMain, TProduct } from '../../../../types';
import { sinks } from './dictionary';
import { Icon } from '../../../common/icon';
import { Modal } from '../../../common/modal/Modal';
import washingImg from '../../../../assets/sinks/washing.png';
import s from './styles.module.css';
import { useOutside } from '../../../../hooks/useOutside';
import { InputStep } from '../input-step';

export const SelectWashing: FC<{ product: TProduct }> = ({ product }) => {
  const { setFieldValue, values } = useFormikContext<TMain>();
  const { isMobile } = useFormikContext<TMain>().values.settings;

  const { sink } = values.calculator[product]!;

  const [isOpen, setIsOpen] = useState(false);

  const position = values.calculator[product]?.sink ? 'selected' : 'empty';

  const selectedTitle = sinks.find((arg) => arg.title === sink);

  const setWashing = (field: string, value: any) => {
    if (value === 'Без мойки') {
      setFieldValue(field, null);
      setFieldValue('calculator.countertop.sinkQuantity', 0);
    } else {
      setFieldValue(field, value);
      setFieldValue('calculator.countertop.sinkQuantity', 1);
    }
  };

  const wrapperRef = useRef(null);
  useOutside(wrapperRef, () => setIsOpen(false));

  const Field: FC<{ washing: IOption; index: number }> = ({ washing }) => {
    const { img, title, description } = washing;
    return (
      <div
        className={s.option}
        onClick={() => {
          setIsOpen(false);
          setWashing(`calculator.${product}.sink`, washing.title);
        }}
      >
        <div className={s.optionLine}>
          {img && <img className={s.img} src={img} alt="" />}
          <div></div>
          <div
            className={`selectTitle ${
              washing.title === values.calculator[product]?.sink
                ? s.selectedTitle
                : ''
            }`}
          >
            <div>{title}</div>
            <div className={s.sub}>{description}</div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className={`${s.selectBlock} ${s[`${product}-${position}`]}`} data-testid="select-washing">
      <button
        className={s.button}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {sink ? (
          <>
            <div>
              <div
                className={`${s.selectTitle} ${
                  isOpen && s.selectedTitleActive
                }`}
              >
                <div className={s.titleContainer}>
                  <div className={s.title}>
                    {selectedTitle?.title || 'без мойки'}
                  </div>
                  <Icon
                    className={s.arrow}
                    img={isOpen ? 'arrow-up-blue' : 'arrow-down-black'}
                  />
                </div>
              </div>

              <img className={s.washingImg} src={washingImg} alt="" />
            </div>
            <div
              style={{
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {!isMobile && <InputStep name="sinkQuantity" />}
            </div>
          </>
        ) : (
          <div className={s.titleContainer}>
            <div className={s.title}>Без мойки</div>
            <Icon
              img={isOpen ? 'arrow-up-blue' : 'arrow-down-black'}
              className={s.arrow}
            />
          </div>
        )}
      </button>

      {isOpen && (
        <div
          className={s.scrollContainer}
          style={sink && !isMobile ? { top: '50%' } : undefined}
          ref={!isMobile ? wrapperRef : null}
        >
          <div id="scrollWidth" className={s.options}>
            <Modal title="Мойка" setIsOpen={setIsOpen}>
              {sinks.map((washing, index) => (
                <Field key={washing.title} washing={washing} index={index} />
              ))}
            </Modal>
          </div>
        </div>
      )}
    </div>
  );
};
