import { IOption } from "../../../../types";

export const hobs: IOption[] = [
  {
    title: "Вырез под варочную панель",
    description: "",
    img: "",
  },
  {
    title: "Без варочной панели",
    description: "",
    img: "",
  },
];
