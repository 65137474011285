import { FC, useState } from 'react';
import { useFormikContext } from 'formik';
import { TMain, TSizesPosition } from '../../../../types';
import { Icon } from '../../../common/icon';
import { Modal } from '../../../common/modal/Modal';
import s from './style.module.css';

const selectOption = (values: number[], selectedValue: number): number[] => {
  const filterValues = values.filter((elem) => elem !== selectedValue);
  filterValues.sort((a, b) => a - b).unshift(selectedValue);
  return filterValues;
};
export const SelectSizeMobile: FC<{
  position: TSizesPosition | 'island';
  fieldName: string;
  label?: string;
}> = ({ position, fieldName, label }) => {
  const { setFieldValue, values } = useFormikContext<TMain>();

  const [vals, setVals] = useState<number[]>([2, 4]);
  const [isOpen, setIsOpen] = useState(false);
  const isIsland = fieldName === 'calculator.island.sizes.thickness';
  const thickness =
    values.calculator[isIsland ? 'island' : 'countertop']!.sizes.thickness;
  const type = values.calculator.countertop.type;

  const Field: FC<{ value: number }> = ({ children, value }) => {
    return (
      <div
        onClick={() => {
          setIsOpen(false);
          setVals(selectOption(vals, value));
          setFieldValue(fieldName, value);
        }}
        className={s.option}
      >
        {children}
      </div>
    );
  };
  if (!thickness) return null;
  return (
    <div
      className={`${s.inputSize} ${s[type !== 'U' ? position : 'thicknessU']}`}
    >
      {!!label && <label className={s.label}>{label}</label>}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`${s.selectBlock} ${
          isOpen ? s.selectBlockOpen : s.selectBlockClose
        }`}
      >
        <div className={`inputDigitalMobile ${s.number}`}>{thickness}</div>
        <div style={{ display: 'flex' }}>
          <Icon className={s.arrow} img="arrow-down-black" />
        </div>
      </button>
      {isOpen && (
        <Modal title="Толщина столешницы" setIsOpen={setIsOpen}>
          <button className={s.options}>
            {vals
              .filter((elem, index) => elem !== thickness)
              .map((value) => (
                <Field key={value} value={value}>
                  <span className={`selectTitle ${s.optionNumber}`}>
                    {value}
                  </span>
                </Field>
              ))}
          </button>
        </Modal>
      )}
    </div>
  );
};
