import { FC, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { TCountertop, TMain } from '../../../../types';
import devider from '../../../../assets/common/devider.svg';
import {
  countertopL,
  countertopQ,
  countertopR,
  countertopU,
} from '../../../app/defaultValues';
import s from './style.module.css';

interface ButtonType {
  types: TCountertop['type'][];
  label: string;
  divider?: 'left' | 'right';
}

const buttonTypes: ButtonType[] = [
  { types: ['Q'], label: 'Прямая', divider: 'left' },
  { types: ['L', 'R'], label: 'Г–образная' },
  { types: ['U'], label: 'П–образная', divider: 'right' },
];

const countetopsValues: Record<TCountertop['type'], TCountertop> = {
  Q: countertopQ,
  L: countertopL,
  R: countertopR,
  U: countertopU,
};

interface TopSelectorProps {
  countertop: TCountertop;
  index: number;
  pathname: string;
}

export const TopSelector: FC<TopSelectorProps> = ({ countertop, pathname }) => {
  const { setFieldValue } = useFormikContext<TMain>();

  useEffect(() => {
    setFieldValue(pathname, countetopsValues[countertop.type], true);
  }, [countertop.type, setFieldValue, pathname]);

  const handleButtonClick = (type: TCountertop['type']) => {
    setFieldValue(`${pathname}.type`, type);
  };

  const renderDivider = (divider: 'left' | 'right' | undefined) =>
    divider ? (
      <img
        className={divider === 'left' ? s.leftDevider : s.rightDevider}
        src={devider}
        alt=""
      />
    ) : null;

  return (
    <div className={s.typeSelector} data-testid="countertop-type">
      {buttonTypes.map(({ types, label, divider }) => (
        <button
          key={label}
          onClick={() => handleButtonClick(types[0])}
          className={`${s.button} ${
            types.includes(countertop.type) ? s.active : ''
          }`}
          data-testid={"countertop-type-" + types[0].toLowerCase()}
        >
          {renderDivider(divider)}
          {label}
        </button>
      ))}
    </div>
  );
};
