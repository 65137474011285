import { FC } from 'react';
import { useFormikContext } from 'formik';
import { TCountertop, TMain, TPanelsPosition } from '../../../../../types';
import { Layer } from '../../../../common/layer';
import front from '../../../../../assets/panels/front.png';
import frontEmpty from '../../../../../assets/panels/front_t.png';
import right from '../../../../../assets/panels/right.png';
import rightShort from '../../../../../assets/panels/right-short.png';
import rightShortEmpty from '../../../../../assets/panels/right-short_t.png';
import rightEmpty from '../../../../../assets/panels/right_t.png';
import left from '../../../../../assets/panels/left.png';
import leftEmpty from '../../../../../assets/panels/left_t.png';
import leftShort from '../../../../../assets/panels/left-short.png';
import leftShortEmpty from '../../../../../assets/panels/left-short_t.png';
import leftBottom from '../../../../../assets/panels/left-bottom.png';
import leftBottomEmpty from '../../../../../assets/panels/left-bottom_t.png';
import rightBottom from '../../../../../assets/panels/right-bottom.png';
import rightBottomEmpty from '../../../../../assets/panels/right-bottom_t.png';

export const Pannel: FC<{ countertop: TCountertop }> = ({ countertop }) => {
  const { type, panels } = countertop;

  const panelsImage: {
    name: TPanelsPosition;
    img: string;
    imgEmpty: string;
    imgShort?: string;
    imgShortEmpty?: string;
  }[] = [
    { name: 'front', img: front, imgEmpty: frontEmpty },
    {
      name: 'left',
      img: left,
      imgEmpty: leftEmpty,
      imgShort: leftShort,
      imgShortEmpty: leftShortEmpty,
    },
    {
      name: 'right',
      img: right,
      imgEmpty: rightEmpty,
      imgShort: rightShort,
      imgShortEmpty: rightShortEmpty,
    },

    { name: 'leftBottom', img: leftBottom, imgEmpty: leftBottomEmpty },
    { name: 'rightBottom', img: rightBottom, imgEmpty: rightBottomEmpty },
  ];

  const getImg = (name: TPanelsPosition): string => {
    const isActive = !!panels.find((p) => p.name === name)?.isActive;
    const currentPanelImage = panelsImage.find((p) => p.name === name);
    if (isActive && currentPanelImage) {
      if (
        (['Q', 'L'].includes(type) && ['right'].includes(name)) ||
        (['Q', 'R'].includes(type) && ['left'].includes(name))
      ) {
        return currentPanelImage.imgShort!;
      }
      return currentPanelImage.img!;
    }
    if (
      (['Q', 'L'].includes(type) && ['right'].includes(name)) ||
      (['Q', 'R'].includes(type) && ['left'].includes(name))
    ) {
      return currentPanelImage?.imgShortEmpty!;
    }

    return currentPanelImage?.imgEmpty || '';
  };

  return (
    <Layer>
      {panels.map(({ name }) => (
        <img key={name} src={getImg(name)} alt={name} />
      ))}
    </Layer>
  );
};
