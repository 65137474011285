import { FC } from "react";
import style from "./style.module.css";

interface IMainGrid {
  title: React.ReactNode;
  main: React.ReactNode;
  right: React.ReactNode;
}

export const MainGrid: FC<IMainGrid> = ({ title, main, right }) => {
  return (
    <div id="calc" className={style.container}>
      <div className={style.header}>{title}</div>
      <div className={style.main}>{main}</div>
      <div className={style.right}>{right}</div>
    </div>
  );
};
