import { stringify } from 'qs';
import axios from 'axios';
import { TColor, TMain } from '../types';
import { CMS_BASE_URL, API_BASE_URL } from '../constants';

function prepareQueryParamForStrapi(fields: string[], param_name: string): { [key: string]: string } {
    return fields.reduce((obj, field, index) => {
        obj[`${param_name}[${index}]`] = field;
        return obj;
    }, {} as { [key: string]: string });
}

export const api = {
  // СПИСОК БРЕНДОВ
  getBrands: async (type: 'acryl' | 'quartz') => {
    const response = await fetch(
      `${CMS_BASE_URL}/api/brands?filters[visible][$eq]=true&filters[material][$eq]=${type}&populate=photo`
    );
    return await response.json();
  },

  // СПИСОК КАМНЕЙ
  getStones: async (
    materialType: string,
    page: number,
    pageSize: number,
    brands: string[] | null,
    userBrands: string[],
    color: TColor | null,
    searchString: string
  ) => {
    // https://github.com/Top-Click/TopClick-Strapi/blob/main/doc/API.md
    // populate = photo для получения ссылок на фото
    // fields = name, price_m_rub для отображения названия и цены за кв.м
    // filters[brand][name][$eq] = brand_name для фильтрации камней по бренду(<- подставить бренд)
    // filters[color][$eq] = white для фильтрации камней по цвету(<- подставить цвет)
    // filters[duplicate][$eq] = false для фильтрации дубликатов
    // filters[price_m_rub][$gt] = 0 для фильтрации по цене(отсутствующим камням)
    // sort = price_m_rub для сортировки по цене(по возрастанию)
    let fields: string[] = ['short_name', 'price_m_rub', 'uid', 'name'];
    let fieldsPrepared: { [key: string]: string } = prepareQueryParamForStrapi(fields, 'fields');
    let populate: string[] = ['photo', 'brand'];
    let populatePrepared: { [key: string]: string } = prepareQueryParamForStrapi(populate, 'populate');
    let reqStrStones: { [name: string]: any } = {
      ...fieldsPrepared,
      ...populatePrepared,
      'filters[brand][name][$eq]': brands,
      'filters[color][$eq]': color,
      'filters[duplicate][$eq]': false,
      'filters[price_m_rub][$gt]': 0,
      'filters[brand][material][$eq]': materialType,
      sort: 'price_m_rub',
      'pagination[page]': page,
      'pagination[pageSize]': pageSize,
      'filters[name][$containsi]': searchString,
    };
    if (userBrands.length > 0) {
      reqStrStones['filters[brand][name][$in]'] = userBrands
    }

    const response = await fetch(
      `${CMS_BASE_URL}/api/stones?${stringify(reqStrStones, {
        skipNulls: true,
        encode: false,
      })}`
    );
    return await response.json();
  },

  // РАСЧЕТ
  calcPrice: async (
    data: TMain['calculator'],
    credentials: { login: string; password: string } | null,
    app_version?: string | '0.0.0',
    app_environment?: string | 'dev'
  ) => {
    const response = axios.post(API_BASE_URL + '/calculate_prices', {
      test: false,
      parameters: data,
      app_version: app_version,
      app_environment: app_environment
    }, {
      auth: {
        username: credentials?.login || '',
        password: credentials?.password || ''
      }
    });
    return await response;
  },

  generateReport: async (
    calc_uid: string,
    credentials: { login: string; password: string }
  ) => {
    const response = axios.post(API_BASE_URL + '/calculation_report', {
      detail: {uid: calc_uid}
    }, {
      auth: {
        username: credentials.login,
        password: credentials.password
      }
    });
    return await response;
  },

  placeOrder: async ({
    fname,
    email,
    phone,
    city,
    contact_type,
    worker_id,
    calc_uid,
    margin,
    credentials,
  }: {
    fname: string;
    email: string;
    phone: string;
    city: string;
    contact_type: string;
    worker_id: number;
    calc_uid: string;
    credentials?: { login: string; password: string } | null;
    margin?: string | null;
  }) => {
    const response = axios.post<{
      offer: string,
      errorMessage?: string
    }>(
      API_BASE_URL + '/place_order',
      {
        name: fname,
        email, // email пользователя
        phone, // телефон пользователя
        city, // город пользователя
        worker_id, // id обработчика (из результатов расчета)
        calc_uid, // uid расчета (из результатов расчета)
        contact_type, // род деятельности
        margin: credentials && margin ? parseInt(margin) : null,
      }, {
        auth: {
          username: credentials?.login || '',
          password: credentials?.password || ''
        }
      }
    );
    return await response;
  },

  apply_worker: async (formData: {
    name: string; // название компании
    email: string; // email для связи
    phone: string; // телефон для связи
  }) => {
    const response = axios.post(API_BASE_URL + '/apply_worker', {
      ...formData,
    });
    return await response;
  },

  reviews: async ({
    worker_name,
    pageSize = 5,
  }: {
    worker_name: string;
    pageSize?: number;
  }) => {
    const reqStrStones: { [name: string]: any } = {
      'filters[worker][name][$eq]': worker_name,
      'pagination[pageSize]': pageSize,
      sort: 'date:desc',
    };

    const response = await fetch(
      `${CMS_BASE_URL}/api/reviews?${stringify(reqStrStones, {
        skipNulls: true,
        encode: false,
      })}`
    );
    return await response.json();
  },

  cities: async (city: string) => {
    const reqStrStones = {
      fields: 'name',
      'pagination[pageSize]': 300,
      'filters[name_lowercase][$startsWith]': city,
      sort: 'population:desc',
    };

    const response = await fetch(
      `${CMS_BASE_URL}/api/cities?${stringify(reqStrStones, {
        skipNulls: true,
        encode: false,
      })}`
    );

    return await response.json();
  },

  getOrder: async (uid: string) => {
    const { data } = await axios.get(
      `${API_BASE_URL}/get_order?uid=${uid}`
    );

    return data;
  },

  login: async (
    login: string,
    password: string
  ): Promise<{
    success: boolean;
    name: string;
    photo: string;
    brands: Array<string>;
  }> => {
    const { data } = await axios.post(API_BASE_URL + '/login', {}, {
      auth: {
        username: login,
        password: password
      }
    });
    return data;
  },
};
