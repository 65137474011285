import { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import { TMain, TMaterial } from "../types";
import { api } from "../api/api";

export type TStone = {
  id: number;
  attributes: {
    uid: string;
    price_m_rub: string;
    short_name: string;
    name: string;
    photo: {
      data: {
        attributes: {
          formats: {
            small: {
              url: string;
            };
            thumbnail: { url: string };
          };
        };
      };
    };
  };
};

type TPagination = {
  page: number;
  pageCount: number;
  pageSize: number;
  total: number;
};

export const useStones = () => {
  const { values, setFieldValue } = useFormikContext<TMain>();
  const { isSelectedStone, isMobileStones } = values.settings;
  const { color, brands, searchString } = values.filter;
  const material: TMaterial = values.calculator.material;
  const userBrands: string[] = values.user.brands;

  const [loading, setLoading] = useState(false);
  const [stones, setStones] = useState<TStone[]>([]);
  const [pagination, setPagination] = useState<TPagination>({
    page: 1,
    pageCount: 0,
    pageSize: 16,
    total: 0,
  });

  useEffect(() => {
    setLoading(true);
    if (brands && Array.isArray(brands)) {
      api
        .getStones(
          material.type,
          pagination.page,
          isMobileStones ? 8 : 16,
          brands,
          userBrands,
          color,
          searchString
        )
        .then(({ meta, data }) => {
          setStones(data);
          setPagination(meta.pagination);
        })
        .finally(() => setLoading(false));
    }
  }, [
    isMobileStones,
    pagination.page,
    pagination.pageSize,
    brands,
    searchString,
    color,
    isSelectedStone,
    material.type,
    userBrands,
    setFieldValue,
  ]);

  useEffect(() => {
    if (!isSelectedStone && stones && stones.length > 0) {
      setFieldValue("calculator.stoneId", stones[0].id);
      const uid = stones.find((st) => st.id === stones[0].id)?.attributes.uid;
      if (uid) setFieldValue("calculator.material.uid", uid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stones, isSelectedStone]);

  const setCurrentPage = (page: number) =>
    setPagination({ ...pagination, page });

  const setPageSize = (pageSize: number) =>
    setPagination({ ...pagination, pageSize });

  return {
    stones,
    pagination,
    loading,
    setCurrentPage,
    setPageSize,
    setStones,
  };
};
