import { FC } from "react";
import s from "./style.module.css";

interface IStoneExample {
  brand: string;
  material: string;
  name: string;
  photo: string;
}

export const StoneExample: FC<IStoneExample> = ({
  brand,
  material,
  name,
  photo,
}) => {
  return (
    <div className={s.stoneExample} data-testid="stone-example">
      <div
        className={s.preview}
        style={{
          width: "80%",
          background: `url(${photo})`,
          border: "solid 1px #eee",
        }}
      ></div>
      <div>{`${brand} ${material === "acryl" ? "Акрил" : "Кварц"}`}</div>
      <div>{name}</div>
    </div>
  );
};
