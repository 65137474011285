import { FC } from 'react';
import s from './style.module.css';
import { CONTACT_PHONE, CONTACT_EMAIL, SUPPORT_LINK } from '../../../constants';
import telegram_icon from '../../../assets/footer/telegram-icon.svg';

export const FooterMini: FC = () => {
  return (
    <div className={s.footer}>
      <div className={s.container}>
        <div className={s.logo}>ТопКлик</div>

        <div className={s.telegram}>
          <a className={s.email} href={SUPPORT_LINK}>
            Обратиться за помощью
          </a>
          <img src={telegram_icon} alt="T" />
        </div>

        <div>
          <a className={s.phoneNumber} href={'tel:' + CONTACT_PHONE}>
            {CONTACT_PHONE}
          </a>
        </div>

        <div>
          <a className={s.email} href={'mailto:' + CONTACT_EMAIL}>
            {CONTACT_EMAIL}
          </a>
        </div>
      </div>

      <div className={s.copyrightMessage}>
        <hr className={s.divider} />
        <div>© 2023 ТопКлик. Все права защищены</div>
      </div>
    </div>
  );
};
