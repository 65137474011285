import { useFormikContext } from 'formik';
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useOutside } from '../../../../../hooks/useOutside';
import { TMain } from '../../../../../types';
import { Icon } from '../../../../common/icon';
import s from './style.module.css';

type TActiveOption = 'edge' | 'plinth' | null;

export const MobileSwitch: FC<{
  fieldName: string;
  onOutside: Dispatch<SetStateAction<boolean>>;
  edgeType: 'edge' | 'plinth';
}> = ({ fieldName, onOutside, edgeType }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState<'right' | 'left' | null>(null);

  // Для того чтобы определить с какой стороны относительно центра находится элемент
  useEffect(() => {
    const leftStyle = ref.current?.parentElement as Element;
    const bodyWidth = document.body.clientWidth;
    const relativePosition =
      Number(window.getComputedStyle(leftStyle).left.replace('px', '')) /
      bodyWidth;
    if (relativePosition >= 0.5) setPosition('right');
    else setPosition('left');
  }, [ref]);

  useOutside(ref, () => onOutside(false));

  const { setFieldValue, getFieldMeta } = useFormikContext<TMain>();
  const activeOption = getFieldMeta(fieldName).value as TActiveOption;

  const handleEdge = () => {
    setFieldValue(fieldName, 'edge');
  };
  const handlePlinth = () => {
    setFieldValue(fieldName, 'plinth');
  };
  const handleDelete = () => {
    setFieldValue(fieldName, null);
  };

  return (
    <div ref={ref} className={`${s.mobileMenu} ${s[position || 'none']}`}>
      <div
        className={`${s.option} ${
          s[activeOption === 'edge' ? activeOption : '']
        }`}
        onClick={handleEdge}
      >
        <span>Кромка</span>
        <Icon img={`${activeOption === 'edge' ? 'k' : 'k-blue'}`} />
      </div>
      <div
        className={`${s.option} ${
          s[activeOption === 'plinth' ? activeOption : '']
        }`}
        onClick={handlePlinth}
      >
        <span>Плинтус</span>
        <Icon img={`${activeOption === 'plinth' ? 'b' : 'b-green'}`} />
      </div>
      <div className={`${s.option} ${s.delete} `} onClick={handleDelete}>
        Удалить
      </div>
    </div>
  );
};
