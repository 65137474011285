import { ChangeEventHandler, FC } from 'react';
import s from './style.module.css';

export const InputData: FC<{
  label: string;
  value: string | number;
  disabled?: boolean;
  maxLength?: number;
  error?: boolean;
  number?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  placeholder?: string;
}> = ({
  label,
  value,
  maxLength,
  disabled,
  error,
  number,
  onChange,
  onBlur,
  placeholder,
}) => {
  return (
    <>
      <div>{label}</div>
      <input
        maxLength={maxLength || 99}
        disabled={disabled}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        className={error ? s.inputOrderError : s.inputOrder}
        type={number ? 'number' : 'text'}
        id="username"
        data-testid="input-data"
      />
    </>
  );
};
